import { BrowserRouter } from 'react-router-dom';
import axios from 'axios';
import globalStore from 'modules/global-store';
import { eventBus } from 'modules/event-emitters';
import App from '@app/App';
import '@app/styles/global.css';
import { loadableReady } from '@loadable/component';
import { I18nProvider } from '@app/providers/i18next-provider';
import { CookiesProvider } from 'react-cookie';
import { HelmetProvider } from 'react-helmet-async';
import { hydrateRoot } from 'react-dom/client';
import storeDataManager from './frontend/storeDataManager';

declare global {
  interface Window {
    eventBus: unknown; // Replace 'any' with the appropriate type for your event bus
    dataLayerITRZ: unknown[];
    ym: (id: number, reachGoal: 'reachGoal', target: string) => void;
    gtag: any;
    dataLayer: unknown[];
    dataLayerAll: unknown;
    fbq: any;
    PAGES_DATA: Record<string, unknown>;
    __SUSHIWOK__: unknown;
  }
}

storeDataManager.init();
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

window.eventBus = eventBus;
// eslint-disable-next-line no-underscore-dangle
window.__SUSHIWOK__ = {
  city: () => {
    const city = globalStore.get('current_city');
    return city.text_id;
  },
};
window.dataLayer = window.dataLayer || [];
window.dataLayer.push({
  event: 'app_init',
});
eventBus.emitEvent('app_init');
window.dataLayerITRZ = window.dataLayerITRZ || [];
window.dataLayerITRZ.push({
  event: 'app_init',
});

storeDataManager
  .asyncFill()
  .then(() => {
    window.dataLayerITRZ.push({
      event: 'products_loaded',
    });
  })
  .catch((e) => console.error(e));

function BaseApp() {
  return (
    <HelmetProvider>
      <CookiesProvider>
        <I18nProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </I18nProvider>
      </CookiesProvider>
    </HelmetProvider>
  );
}

loadableReady(() => {
  const container = document.getElementById('root')!;
  hydrateRoot(container, <BaseApp />);
});
