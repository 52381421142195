import { clsx } from 'clsx';
import { SmartLink } from '@shared/lib/routing';
import { useIntervalIndex } from '../../model/useIntervalIndex';
import { INTERVAL } from '../../config/constants';
import styles from './DesktopMenu.module.scss';
import type { IMenuElement } from '../../model/types';
import { Icon } from '../icon/Icon';

export function DesktopMenu({
  mainDomain,
  menuList,
}: {
  mainDomain: string;
  menuList: IMenuElement[];
}) {
  const menu = menuList.filter(({ visible }) => visible);
  const animatedIndex = useIntervalIndex(menu.length, INTERVAL);
  return (
    <div className={`bg-white-translucent ${styles.root}`}>
      {menu.map((item, index) => (
        <SmartLink
          href={`/menu/${item.text_id}`}
          className={clsx(styles.box, {
            [styles.iconAnimated]: index === animatedIndex,
          })}
          activeClassName={styles.iconActive}
          key={item.text_id}
        >
          <Icon
            width={45}
            height={45}
            item={item}
            mainDomain={mainDomain}
            className={styles.icon}
          />
          <div className={styles.iconDescr}>
            <span className={styles.iconDescrTitle}>{item.title}</span>
          </div>
        </SmartLink>
      ))}
    </div>
  );
}
