import Rolls from '../assets/rolls.svg';
import Baked from '../assets/baked.svg';
import Sets from '../assets/sets.svg';
import Sushi from '../assets/sushi.svg';
import Nutriment from '../assets/nutriment.svg';
import Wok from '../assets/wok.svg';
import Pizza from '../assets/pizza.svg';
import Salads from '../assets/salads.svg';
import Soups from '../assets/soups.svg';
import Hotter from '../assets/hotter.svg';
import Blunch from '../assets/blunch.svg';
import Beverages from '../assets/beverages.svg';
import Additionally from '../assets/additionally.svg';
import Postnoe from '../assets/postnoe.svg';
import Lunch from '../assets/lunch.svg';

export const iconList = [
  { id: 1, filename: 'rolls', Icon: Rolls },
  { id: 2, filename: 'baked', Icon: Baked },
  { id: 3, filename: 'sets', Icon: Sets },
  { id: 708, filename: 'sushi', Icon: Sushi },
  { id: 710, filename: 'nutriment', Icon: Nutriment },
  { id: 709, filename: 'wok', Icon: Wok },
  { id: 711, filename: 'pizza', Icon: Pizza },
  { id: 712, filename: 'salads', Icon: Salads },
  { id: 713, filename: 'soups', Icon: Soups },
  { id: 714, filename: 'hotter', Icon: Hotter },
  { id: 716, filename: 'blunch', Icon: Blunch },
  { id: 717, filename: 'lunch', Icon: Lunch },
  { id: 718, filename: 'beverages', Icon: Beverages },
  { id: 719, filename: 'additionally', Icon: Additionally },
  { id: 1217, filename: 'postnoe-menu', Icon: Postnoe },
] as const;
