import { ExternalImage } from '@shared/ui/image';
import { PropsWithChildren } from 'react';

export interface IImgProps {
  mainDomain: string;
  src?: string;
  alt?: string;
  width?: number;
  height?: number;
  className?: string;
}

export interface ISvgProps extends React.SVGAttributes<SVGSVGElement> {
  width: number;
  height: number;
  className?: string;
}

export type ILinkProps = React.AnchorHTMLAttributes<HTMLAnchorElement>;

export const linkReplacer = (props: ILinkProps, children: React.ReactNode) => (
  <a className="default-styled-link" {...props}>
    {children}
  </a>
);

export const imageReplacer = (
  props: Record<string, unknown>,
  imgProps: IImgProps,
) => {
  const attributesProps = props as Partial<IImgProps>;
  return (
    <ExternalImage
      mainDomain={imgProps.mainDomain}
      alt={attributesProps.alt || imgProps.alt || ''}
      src={attributesProps.src?.replace('/img', '') || ''}
      width={attributesProps.width || imgProps.width!}
      height={attributesProps.height || imgProps.height!}
      className={imgProps.className}
    />
  );
};

export const svgReplacer = ({
  children,
  width,
  height,
  className,
  ...props
}: PropsWithChildren<ISvgProps>) => (
  <svg width={width} height={height} className={className} {...props}>
    {children}
  </svg>
);
