import { htmlParse } from './htmlParse';
import { IImgProps, imageReplacer, linkReplacer } from './replacers';

interface IHtmlParserProps {
  data: string;
  imgProps: IImgProps;
}

export function HtmlParser({ data, imgProps }: IHtmlParserProps) {
  if (!data) return null;

  const tableStyles =
    '[&_table]:w-full [&_td]:border [&_td]:border-verylightgray [&_td]:p-3 sm:[&_td]:p-5';

  return (
    <div className={`*:m-[revert] *:p-[revert] ${tableStyles}`}>
      {htmlParse(data, [
        {
          from: 'img',
          to: (props) => imageReplacer(props, imgProps),
        },
        {
          from: 'a',
          to: linkReplacer,
        },
      ])}
    </div>
  );
}
