import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from 'modules/helpers/api';
import { afterCartItem } from 'modules/helpers/cart-helper';
import { t } from 'i18next'; // TODO удалить, испльзовать через хук
import type { ICart, ICartItem } from 'types/cartTypes';
import {
  IComplectationFree,
  IComplectationItem,
  IComplectationPaid,
  IComplectationState,
} from '../types';

const paidItemsSender = async (
  toSendItems: { id: number; qty: number }[],
  onError: (text: string) => void,
) => {
  try {
    const newCart: ICart = await api('cart.items', toSendItems);
    await afterCartItem({ newCart, t, onError });
    return newCart;
  } catch (e) {
    throw Error(String(e));
  }
};

export const sortCartComplectation = ({
  items,
  compItems,
}: {
  items: ICartItem[];
  compItems: IComplectationItem[];
}) => {
  const paid = compItems
    .sort((a, b) => a.position - b.position)
    .map<IComplectationPaid>(({ id }) => {
      const compInCart = items.find(({ id: cartItemId }) => id === cartItemId);
      return { product_id: id, qty: compInCart?.qty ?? 0 };
    });

  return paid;
};

const initState: IComplectationState = {
  free: [],
  paid: [],
  isLoading: false,
};

export const addPaid = createAsyncThunk<
  IComplectationPaid[],
  { toSendItems: IComplectationPaid[]; onError: (text: string) => void }
>('complectation/addPaid', async ({ toSendItems, onError }) => {
  const sendItems = toSendItems.map(({ product_id, qty }) => ({
    id: product_id,
    qty,
  }));
  const { items, complectations } = await paidItemsSender(sendItems, onError);
  if (!complectations || !complectations.length) {
    return [];
  }
  const paid = sortCartComplectation({
    items,
    compItems: complectations,
  });
  return paid;
});

export const deleteAllPaid = createAsyncThunk<
  IComplectationPaid[],
  (text: string) => void
>('complectation/deleteAllPaid', async (onError, thunkApi) => {
  const {
    complectation: { paid: currentPaid },
  } = thunkApi.getState() as RootState;
  const deleteItems = currentPaid.map(({ product_id, qty }) => ({
    id: product_id,
    qty: -qty,
  }));
  if (!deleteItems.length) return [];
  const { items, complectations } = await paidItemsSender(deleteItems, onError);
  if (!complectations || !complectations.length) {
    return [];
  }
  const paid = sortCartComplectation({
    items,
    compItems: complectations,
  });
  return paid;
});

export const complectationSlice = createSlice({
  name: 'complectation',
  initialState: initState,

  reducers: (create) => ({
    addFree: create.reducer(
      (state, action: PayloadAction<{ id: number; qty: number }>) => {
        const { free } = state;
        const { id, qty } = action.payload;
        const newFree = free.map((item) => {
          if (item.product_id === id) return { ...item, qty: item.qty + qty };
          return item;
        });
        state.free = newFree;
      },
    ),

    removeFreeItems: create.reducer(
      (state, action: PayloadAction<number[]>) => {
        const newFree = state.free.map((item) => {
          if (action.payload.includes(item.product_id)) {
            return { ...item, qty: 0 };
          }
          return item;
        });
        state.free = newFree;
      },
    ),

    setFree: create.reducer(
      (state, action: PayloadAction<IComplectationFree[]>) => {
        state.free = action.payload;
      },
    ),

    setPaid: create.reducer(
      (state, action: PayloadAction<IComplectationPaid[]>) => {
        state.paid = action.payload;
      },
    ),

    resetComplectation: create.reducer((state) => {
      state.free = [];
      state.paid = [];
      state.isLoading = false;
    }),
  }),

  extraReducers: (builder) => {
    builder.addCase(addPaid.fulfilled, (state, action) => {
      state.paid = action.payload;
      state.isLoading = false;
    });
    builder.addCase(addPaid.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(addPaid.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(deleteAllPaid.fulfilled, (state, action) => {
      state.paid = action.payload;
      state.isLoading = false;
    });
    builder.addCase(deleteAllPaid.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteAllPaid.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

const selectComplectationState = (state: RootState) => state.complectation;

const selectFree = (state: RootState) => state.complectation.free;

const selectPaid = (state: RootState) => state.complectation.paid;

const selectIsLoading = (state: RootState) => state.complectation.isLoading;

export const complectationSliceSelectors = {
  selectComplectationState,
  selectFree,
  selectPaid,
  selectIsLoading,
};

export const {
  addFree,
  removeFreeItems,
  setFree,
  setPaid,
  resetComplectation,
} = complectationSlice.actions;
