import { useEffect, useRef, useState } from 'react';
import Dropdown from '@shared/ui/dropdown/Dropdown';
import type {
  ICoordinates,
  IFullAddress,
} from '@shared/third-party-libs/commonMap';
import { useDebounce } from '@shared/lib/debounce';
import styles from './Suggest.module.scss';

export interface ISearchConfig {
  resultsNumber: number;
  reqMinLength: number;
  debounceDelay: number;
}

export interface IGeoSuggestItem {
  displayName: string;
  value: string;
  hl?: unknown[];
  type?: 'geo' | string;
}

export type UseSugestType = ({
  cityName,
  cityCoords,
  lang,
  searchConfig,
}: {
  cityName: string;
  cityCoords: ICoordinates;
  lang: string;
  searchConfig: ISearchConfig;
}) => {
  suggestRequest: (req: string) => Promise<
    {
      displayName: string;
      value: string;
    }[]
  >;
  debounceDep: unknown;
};

export interface ISuggestProps {
  placeholder?: string;
  onChange?: (value: string) => void;
  name?: string;
  onBlur?: (e: React.FocusEvent<HTMLInputElement, Element>) => void;
  required?: string;
  initialValue?: string;
  searchConfig: ISearchConfig;
  cityName: string;
  cityCoords: ICoordinates;
  lang: string;
  useSuggest: UseSugestType;
  setAddress: (center: string) => void;
  fullAddress: IFullAddress | null;
}

export function Suggest({
  searchConfig,
  placeholder,
  name = '',
  required = '',
  initialValue,
  fullAddress,
  lang,
  setAddress,
  onBlur,
  onChange,
  useSuggest,
  cityName,
  cityCoords,
}: ISuggestProps) {
  const suggestRef = useRef<HTMLDivElement>(null);
  const inputref = useRef<HTMLInputElement>(null);

  const [isDropdownVisible, setIsDropdownVisible] = useState<boolean>(false);
  const [searchResult, setSearchResult] = useState<IGeoSuggestItem[]>([]);
  const [currentAddress, setCurrentAddress] = useState<string>('');
  const [activeInSuggest, setActiveInSuggest] = useState<string | null>(null);

  const { suggestRequest, debounceDep } = useSuggest({
    cityName,
    cityCoords,
    lang,
    searchConfig,
  });

  useEffect(() => {
    if (!initialValue) return;
    setCurrentAddress(initialValue);
    setAddress(initialValue);
    if (onChange) onChange(initialValue as string);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!fullAddress) return;
    setSearchResult([]);
    const { city, street, house } = fullAddress;
    setCurrentAddress(`${city}, ${street}, ${house}`);
    setActiveInSuggest(null);
  }, [fullAddress]);

  const suggestSearch = async (req: string) => {
    try {
      const res = await suggestRequest(req);
      setSearchResult(res);
      if (document.activeElement === inputref.current) {
        setIsDropdownVisible(!!res.length);
      }
    } catch (e: unknown) {
      throw new Error(String(e));
    }
  };

  const debouncedSuggestSearch = useDebounce(
    suggestSearch,
    searchConfig.debounceDelay,
    [debounceDep],
  );

  const keyDownHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const { key } = e;
    if (key === 'Enter') {
      inputref.current?.blur();
    }
  };

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = e;
    // Если событие случилось на неактивном инпуте (например autocomplete при заполнении другого поля),
    //  необходимо обработать это значение как выбор адреса.
    if (document.activeElement !== inputref.current) {
      setAddress(value);
      if (onChange) onChange(value);
      return;
    }
    if (activeInSuggest) setActiveInSuggest(null);
    setCurrentAddress(value);
    debouncedSuggestSearch(value);
  };

  const onDropDownSelect = () => {
    inputref.current?.blur();
  };

  const closeDropdown = () => {
    setIsDropdownVisible(false);
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    setAddress(activeInSuggest || currentAddress);
    if (onChange) onChange(activeInSuggest || currentAddress);
    if (onBlur) onBlur(e);
    setIsDropdownVisible(false);
  };

  const menuData = searchResult.map((item, index) => ({
    value: item.value,
    label: item.displayName,
    key: `${index}.${item.value}`,
  }));

  const onFocus = () => {
    if (searchResult.length) {
      setIsDropdownVisible(true);
      return;
    }
    suggestSearch(currentAddress);
  };

  return (
    <div className={styles.wrapper} ref={suggestRef}>
      <Dropdown
        visible={isDropdownVisible}
        menuData={menuData}
        onSelect={onDropDownSelect}
        closeDropdown={closeDropdown}
        onOverlayClick={closeDropdown}
        dataChange={!activeInSuggest}
        placement="top"
        setActiveInSuggest={setActiveInSuggest}
      >
        <input
          id="suggest"
          name={name}
          required={!!required}
          className={styles.suggest}
          value={activeInSuggest || currentAddress}
          ref={inputref}
          type="text"
          autoComplete="off"
          onChange={onInputChange}
          onKeyDown={keyDownHandler}
          placeholder={placeholder}
          onFocus={onFocus}
          onBlur={handleBlur}
        />
      </Dropdown>
    </div>
  );
}
