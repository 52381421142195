import { Component } from 'react';
import globalStore from 'modules/global-store';
import { seoEmitter } from 'src/modules/event-emitters';

export class DEPRECATED_CUSTOM_TEXT extends Component {
  constructor(props) {
    super(props);

    const { custom_text } = globalStore.get('seo', {});
    this.state = {
      customText: custom_text,
    };
  }

  componentDidMount() {
    seoEmitter.addListener('SEO_UPDATE', this.updateCustomText);
  }

  componentWillUnmount() {
    seoEmitter.removeListener('SEO_UPDATE', this.updateCustomText);
  }

  updateCustomText = () => {
    const { custom_text } = globalStore.get('seo', {});
    this.setState({
      customText: custom_text,
    });
  };

  render() {
    const { customText = '' } = this.state;
    return customText ? (
      <div
        dangerouslySetInnerHTML={{ __html: customText }}
        className={this.props.className}
      />
    ) : null;
  }
}
