import { useModal } from '@ebay/nice-modal-react';
import { modalIds } from '@shared/config/modal-ids';
import { useCallback } from 'react';
import type { ConfirmCityModalArgs } from './types';

export const useConfirmCityModal = () => {
  const modal = useModal(modalIds.ConfirmCityModalId);
  const show = useCallback(
    (args: ConfirmCityModalArgs) => modal.show(args),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  return {
    ...modal,
    show,
  };
};
