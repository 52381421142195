import './Favorites.scss';
import './FavoritesMobile.scss';
import * as session from 'modules/session';
import { useTranslation } from 'react-i18next';
import useFavorites from 'frontend/data/user/favorites/useFavorites';
import { CatalogGrid } from '@shared/ui/catalog-grid';
import Card from '../Products/Card/Card';

function Favorites() {
  const { t } = useTranslation();
  const { data: favorites } = useFavorites();

  const { items = [] } = session.get('cart');

  return favorites && favorites.length > 0 ? (
    <CatalogGrid maxInRow="4">
      {favorites.map((product) => {
        const cart_item = items.find((item: any) => item.id === product.id);
        return (
          <Card
            key={product.id}
            product={product}
            view_type="grid"
            position="fromFavorites"
            cart_item={cart_item}
          />
        );
      })}
    </CatalogGrid>
  ) : (
    <div className="favorite__error">
      <p>
        {' '}
        {t('Favorites.press_stars')} <br /> {t('Favorites.in_menu')} ...
      </p>
      <p>... {t('Favorites.goods_will_appear')}</p>
    </div>
  );
}

export default Favorites;
