import { Provider } from 'react-redux';
import { makeStore } from '@app/store';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { ReactNode } from 'react';

export function StoreProvider({ children }: { children: ReactNode }) {
  const preloadedState = makeStore().getState(); // for server
  const store = makeStore(preloadedState);
  const persistor = persistStore(store);

  return (
    <Provider store={store} serverState={preloadedState}>
      <PersistGate loading={children} persistor={persistor}>
        {children}
      </PersistGate>
    </Provider>
  );
}
