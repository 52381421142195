import { Component } from 'react';
import autobind from 'autobind-decorator';
import * as session from 'modules/session';
import { withTranslation } from 'react-i18next';
import { Button } from '@shared/ui/button';
import { ModalParagraph } from '@shared/ui/modal-paragraph';
import { SmartLink } from '@shared/lib/routing';
import { getBanknotes } from './lib';
import Emitter from '../../../Emitters';
import styles from './paymentModal.module.scss';
// eslint-disable-next-line css-modules/no-unused-class
import modalStyles from '../../main-card-modal/mainCartModal.module.scss';

class PaymentModal extends Component {
  constructor(props) {
    super(props);
    const {
      value: { payment_type },
    } = this.props;
    const isCourierCash = payment_type === 'ch';
    const isCardOnline = payment_type === 'co';
    const isCourierCard = payment_type === 'cc';

    this.state = {
      isCourierCash,
      isCardOnline,
      isCourierCard,
      disableButton: isCourierCash,
    };
  }

  setValue = (event, value) => {
    event.preventDefault();
    this.input.value = String(parseInt(value, 10) || 0);
    this.changeHandler({ target: { value: String(parseInt(value, 10) || 0) } });
  };

  getCardOnlineAdditionalText = (t) => {
    const {
      order: { organizationContactInformation },
    } = session.get('cart');

    if (!organizationContactInformation) {
      return <p>{t('PaymentModal.whenYouClickOnTheButtonCheckout')}</p>;
    }

    return (
      <>
        <p>{t('PaymentModal.whenYouClickOnTheButtonCheckout')}</p>
        <p>
          Оформляя заказ, вы подтверждаете, что ознакомились с{' '}
          <SmartLink
            isDefaultStyled
            href="/page/sber-acquiring"
            target="_blank"
          >
            процессом передачи персональных данных
          </SmartLink>{' '}
          и{' '}
          <SmartLink isDefaultStyled href="/dannye-prodavca" target="_blank">
            контактными данными организации
          </SmartLink>
          , оказывающей услугу.
        </p>
      </>
    );
  };

  @autobind
  changeHandler({ target }) {
    target.value = target.value.replace(/[^0-9]/g, '');

    const { isCourierCash } = this.state;
    if (isCourierCash) {
      const {
        value: { total },
      } = this.props;
      const disableButton =
        target.value === '' || (+target.value !== 0 && +target.value < +total);
      this.setState({
        disableButton,
      });
    }
  }

  @autobind
  confirmCash({ target }) {
    target.disabled = true;
    const { value: change_from } = this.input;
    Emitter.emit('CHECKOUT', change_from);
    Emitter.emit('DO_NOT_CLOSE_MODAL', true);
  }

  @autobind
  confirm({ target }) {
    target.disabled = true;
    Emitter.emit('CHECKOUT');
    Emitter.emit('DO_NOT_CLOSE_MODAL', true);
  }

  render() {
    const {
      value: { total, isRussia, banknotes },
      t,
      currency,
    } = this.props;
    const { isCourierCash, isCourierCard, isCardOnline, disableButton } =
      this.state;
    return (
      <div className={styles.courierCashModal}>
        <ModalParagraph>
          {isCourierCash &&
            t('PaymentModal.what_is_the_minimum_amount_need_to_change')}
          {isCourierCard && t('PaymentModal.payment_to_the_courier_card')}
          {isCardOnline && t('PaymentModal.card_payment_online')}
        </ModalParagraph>
        <div className={styles.textRow}>
          <p>
            {t('PaymentModal.the_amount_of_your_order_is')} {total}&nbsp;
            {currency}
          </p>
        </div>
        {isCourierCash && (
          <div className={styles.inputRow}>
            <label htmlFor="deal-input">
              {t('PaymentModal.what_is_the_minimum_amount_need_to_change')} *
            </label>
            {banknotes.length ? (
              <span>
                {t('PaymentModal.weDoNotAcceptBanknotes')}{' '}
                {banknotes.join(', ')}&nbsp;{currency}
              </span>
            ) : null}
            <input
              type="text"
              id="deal-input"
              maxLength={5}
              ref={(input) => (this.input = input)}
              pattern="^[\d]{3,6}$"
              onChange={this.changeHandler}
            />
            {isRussia && (
              <div className={styles.banknotes}>
                {getBanknotes(total).map((banknote) => (
                  <button
                    type="button"
                    onClick={(e) => this.setValue(e, banknote)}
                    key={banknote}
                  >
                    {banknote === 0 ? t('PaymentModal.no_change') : banknote}
                  </button>
                ))}
              </div>
            )}
          </div>
        )}
        {isCardOnline && (
          <div className={styles.textRow}>
            {this.getCardOnlineAdditionalText(t)}
          </div>
        )}
        <div className={modalStyles.btnContainer}>
          <Button
            isFullWidth
            disabled={disableButton}
            onClick={(e) =>
              isCourierCash ? this.confirmCash(e) : this.confirm(e)
            }
          >
            {t('localization.placeYourOrder')}
          </Button>
        </div>
      </div>
    );
  }
}

export default withTranslation()(PaymentModal);
