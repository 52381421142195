import { clsx } from 'clsx';
import { useTranslation } from 'react-i18next';
import { ExternalImage } from '@shared/ui/image';
import Counter from '@shared/ui/Counter/Counter';
import { IProduct } from 'types/product';
import { IUserGift } from 'types/userTypes';
import { useSelectCountryMainDomain } from '@entities/countries';
import { AvailableMethodsOfReceiving } from '@entities/product';
import style from './Card.module.scss';

interface IGiftCard {
  product: IProduct;
  maxQty: number;
  changeQty: (gift: IUserGift) => void;
  qty: number;
  isExact: boolean;
}

function Card({ product, maxQty, changeQty, qty, isExact }: IGiftCard) {
  const { t } = useTranslation();
  const mainDomain = useSelectCountryMainDomain();

  const renderAddButton = () =>
    !isExact ? (
      <div className={style.qtyContainer}>
        <span>{`x ${qty}`}</span>
      </div>
    ) : (
      <Counter
        qty={qty}
        step={1}
        disabled={qty >= maxQty}
        className={style.qtyContainer}
        setQty={(value) =>
          changeQty({ qty: value, maxQty, product_id: product.id })
        }
      />
    );

  return (
    <div className={clsx('card-wrapper--widget', style.cardContainer)}>
      <ExternalImage
        mainDomain={mainDomain}
        className={style.imgWrapper}
        src={`${product.images[0]?.filename}/80x80`}
        alt={product.title}
        width={80}
        height={80}
      />
      <div className={clsx('card-widget__main-content', style.content)}>
        <div className={style.textContainer}>
          <div className="card-widget__name">{product.title}</div>
          <span className={style.qty}>{`${maxQty} ${t('Card.unit_шт')}`}</span>
          <AvailableMethodsOfReceiving {...product} />
        </div>
        <div className={style.btnContainer}>
          <span className={style.price}>{t('Card.free')}</span>
          {renderAddButton()}
        </div>
      </div>
    </div>
  );
}
export default Card;
