import type { IMenuElement } from '@entities/menu';
import { ExternalImage } from '@shared/ui/image';

import clsx from 'clsx';
import { htmlParse, svgReplacer } from '@shared/html';
import { iconList } from '../../model/iconList';
import styles from './icon.module.scss';

export function Icon({
  item,
  mainDomain,
  width,
  height,
  className,
}: {
  item: IMenuElement;
  mainDomain: string;
  width: number;
  height: number;
  className?: string;
}) {
  const iconClassname = clsx(styles.root, className);

  const image = item.images_site?.[0];

  if (image?.data) {
    return htmlParse(image.data, [
      {
        from: 'svg',
        to: (props, children) =>
          svgReplacer({
            ...props,
            className: iconClassname,
            width,
            height,
            children,
          }),
      },
    ]);
  }
  if (image?.filename) {
    return (
      <ExternalImage
        mainDomain={mainDomain}
        width={width}
        height={height}
        className={iconClassname}
        alt={image.alt || item.title}
        src={`${image.filename}.svg`}
      />
    );
  }

  const icon = iconList.find((i) => item.id === i.id);
  if (icon) {
    return (
      <icon.Icon width={width} height={height} className={iconClassname} />
    );
  }
  return null;
}
