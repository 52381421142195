import { Component } from 'react';
import globalStore from 'modules/global-store';
import queryString from 'query-string';
import api from 'modules/helpers/api';
import ssr from 'modules/decorators/ssr';
import { FilterTags } from '@features/filter-tags';
import { Breadcrumbs } from '@shared/ui/breadcrumbs';
import { PageTitle } from '@shared/ui/titles';
import { PageContainer } from '@shared/ui/content-containers';
import { DEPRECATED_BANNER } from '@widgets/seoPage';
import { CatalogGrid } from '@shared/ui/catalog-grid';
import ArticleCard from '../Articles/ArticleCard/ArticleCard';
import { DEPRECATED_CUSTOM_TEXT } from '../../Components/CustomText/CustomText';
import { DEPRECATED_CUSTOM_HELMET } from '../../Components/CustomHelmet';
import styles from './news.module.scss';

const LIMIT = 3;

class News extends Component {
  static LOADED_KEY = 'news_loaded';

  static initialData(fetch, params = {}, globalStore) {
    const { tag, page } = params;
    return Promise.all([
      fetch('news.list', { limit: LIMIT, tag, page }).then((result) => {
        if (!Array.isArray(result)) {
          result = [];
        }
        return globalStore.set('news_list', result);
      }),
      fetch('news.categories').then((result) =>
        globalStore.set('news_categories', result),
      ),
    ])
      .then(() => [])
      .catch((e) => {
        console.error('News', e);
        return [];
      });
  }

  static isLast(entities = []) {
    return entities.some((n) => n.last);
  }

  constructor(props) {
    const query = queryString.parse(props.location.search || '');
    const { tag } = query;
    super(props);
    this.state = {
      tag,
      page: 0,
      likes: globalStore.take('news_likes'),
      news_list: globalStore.get('news_list', []),
      news_categories: globalStore.get('news_categories', []),
    };
  }

  componentDidMount() {
    const { location } = this.props;
    const query = queryString.parse(location.search || '');
    const { tag } = query;
    if (globalStore.get(News.LOADED_KEY)) {
      globalStore.unlink(News.LOADED_KEY);
    } else {
      News.initialData(api, { tag }, globalStore).then(() => {
        this.setState({
          news_list: globalStore.get('news_list', []),
          news_categories: globalStore.get('news_categories', []),
        });
      });
    }
  }

  changeQuery = (newTag = null) => {
    let { tag } = this.state;
    if (newTag === tag) {
      return;
    }
    tag = newTag;
    this.setState({ tag, page: 0 });
    globalStore.set('news_list', []);
    this.updateData(tag);
  };

  next = () => {
    let { page = 0 } = this.state;
    const { tag } = this.state;
    page++;
    this.setState({ page });
    this.updateData(tag, page);
  };

  updateData = (tag = null, page = 0) => {
    const queryRequest = { limit: LIMIT };

    const { history, location } = this.props;
    const { pathname } = location;
    const query = {};
    const news = globalStore.get('news_list', []);
    if (tag) {
      queryRequest.tag = tag;
      query.tag = tag;
    }
    if (page) {
      queryRequest.page = page;
    }
    history.push({
      pathname,
      search: query ? queryString.stringify(query) : '',
    });
    News.initialData(api, queryRequest, globalStore).then(() => {
      globalStore.set(
        'news_list',
        news.concat(globalStore.get('news_list', [])),
      );
      this.setState({
        news_list: globalStore.get('news_list', []),
        news_categories: globalStore.get('news_categories', []),
        page,
      });
    });
  };

  render() {
    const { tag, likes, news_list = [], news_categories = [] } = this.state;
    const isLast = News.isLast(news_list) || !news_list.length;
    const {
      location: { pathname },
      t,
    } = this.props;

    const pageName = t('news.newsTitle');
    return (
      <>
        <DEPRECATED_BANNER pathname="/news" />
        <DEPRECATED_CUSTOM_HELMET title={pageName} type="article" />

        <PageContainer>
          <Breadcrumbs
            links={[
              {
                text: pageName,
              },
            ]}
          />
          <PageTitle>{pageName}</PageTitle>
          <FilterTags
            changeQuery={this.changeQuery}
            filters={news_categories}
            selectedTag={tag}
          />
          <CatalogGrid maxInRow="3">
            {news_list.map(
              ({
                announcement_text,
                title,
                images = [],
                publication_from,
                category_id,
                id,
                text_id,
                name,
              }) => {
                const finded =
                  news_categories.find((news) => news.id === category_id) || {};
                const { text_id: tag, name: category_name } = finded;
                return (
                  <ArticleCard
                    key={id}
                    {...{
                      announcement_text,
                      title,
                      name,
                      images,
                      publication_from,
                      tag,
                      likes,
                      id,
                      text_id,
                      pathname,
                      type: 'news',
                      category_name,
                    }}
                  />
                );
              },
            )}
          </CatalogGrid>
          {!isLast && (
            <div className={styles.showMoreButtonWrapper}>
              <button
                onClick={() => {
                  this.next();
                }}
                className={styles.showMoreButton}
                type="button"
              >
                {t('localization.showMore')}
              </button>
            </div>
          )}
          <DEPRECATED_CUSTOM_TEXT />
        </PageContainer>
      </>
    );
  }
}

export default ssr(News);
