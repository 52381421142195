import './VacanciesModal.scss';
import autobind from 'autobind-decorator';
import { Component } from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import globalStore from 'modules/global-store';
import { modalEmitter } from 'modules/event-emitters';
import validators from 'modules/form-validators';
import mask from 'modules/mask/phone';
import removeUnwantedCharacters from 'modules/helpers/remove-unwanted-characters';
import escapeReg from 'modules/helpers/escape-regexp';
import { withTranslation } from 'react-i18next';
import phoneValidator from 'modules/mask/phoneValidator';
import { Button } from '@shared/ui/button';
import { withCheckCountries } from '@entities/countries';
import { clsx } from 'clsx';
import Textarea from '../../Elements/Textarea/Textarea';

const customStyles = {
  overlay: {
    zIndex: 20,
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
    overflowY: 'auto',
  },
  content: {
    maxWidth: '500px',
    top: '10%',
    left: '0',
    right: '0',
    bottom: 'auto',
    margin: 'auto',
    overflow: 'visible',
    padding: '30px 60px 36px',
    border: 'none',
    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 16px 60px 20px',
    boxSizing: 'border-box',
  },
};

class VacanciesModal extends Component {
  formData = {
    vacancy_id: '',
    email: '',
    contact: '',
    phone: '',
    about: '',
    age: '',
    residence_city: '',
    nationality: '',
  };

  formRules = {
    phone: 'phone',
    contact: 'string:100',
    email: 'email',
    age: 'age',
    residence_city: 'string:100',
    nationality: 'string:100',
  };

  constructor(props) {
    super(props);

    this.state = {
      sended: false,
      modalIsOpen: false,
      formSubmitted: false,
      formValidators: { ...this.formRules },
      form: { ...this.formData },
      invalidFields: {},
      checkedInfo: true,
    };
  }

  componentDidMount() {
    Modal.setAppElement('body');
    modalEmitter.addListener('Vacancies.Modal.Open', this.showModal);
    modalEmitter.addListener('Vacancies.Modal.Close', this.closeModal);
  }

  componentWillReceiveProps(props) {
    this.setState({
      modalIsOpen: props.opened,
    });
  }

  componentWillUnmount() {
    modalEmitter.removeListener('Vacancies.Modal.Open', this.showModal);
    modalEmitter.removeListener('Vacancies.Modal.Close', this.closeModal);
  }

  @autobind
  handleSubmit(event) {
    event.preventDefault();

    const { invalidFields, form, checkedInfo } = this.state;
    if (Object.keys(invalidFields).length > 0 || !checkedInfo) {
      this.setState({ formSubmitted: true });
    } else {
      axios.post('/vacancies/feedback', form).then(() => {
        this.setState({ sended: true });
      });
    }
  }

  @autobind
  handleChange(event) {
    const { target } = event;
    const { form } = this.state;
    const current_city = globalStore.get('current_city');

    if (target.type === 'tel') {
      if (target.value === 9 && current_city.domain === 'ru') {
        target.value = '+79';
      }
      mask(event);
    }

    if (target.name === 'contact') {
      removeUnwantedCharacters(target, escapeReg('-'));
    }

    if (target.name === 'age') {
      target.value = target.value
        .replace(/[^0-9]/g, '')
        .replace(/^0+/g, '')
        .substr(0, 2);
    }

    form[target.name] = target.value;

    this.setState({ form });
  }

  @autobind
  closeModal() {
    this.setState({
      sended: false,
      modalIsOpen: false,
      formSubmitted: false,
      form: { ...this.formData },
    });
  }

  @autobind
  showModal(id) {
    this.setState({
      modalIsOpen: true,
      form: { ...this.formData, vacancy_id: id },
      sended: false,
    });
  }

  @autobind
  toggleShow() {
    this.setState((prevState) => ({
      checkedInfo: !prevState.checkedInfo,
    }));
  }

  validateValue(value) {
    const { formSubmitted, invalidFields } = this.state;
    return formSubmitted && invalidFields[value];
  }

  validateForm() {
    const { form, formValidators, invalidFields } = this.state;
    validators.validate(form, formValidators, invalidFields, [
      'age',
      'email',
      'nationality',
    ]);
    if (!phoneValidator.isValid(form.phone)) {
      invalidFields.phone = true;
    }
  }

  @autobind
  renderForm() {
    this.validateForm();

    const { checkedInfo } = this.state;
    const { t, isUkraine } = this.props;
    return (
      <form
        className="form vacancies-modal"
        onSubmit={(e) => this.handleSubmit(e)}
      >
        <h3 className="form__title">{t('localization.sendResume')}</h3>
        <div
          className={`input-container ${this.validateValue('contact') ? 'error' : undefined}`}
        >
          {this.validateValue('contact') ? (
            <label className="form__label form__label--required">
              {t('localization.Invalid-name')}
            </label>
          ) : (
            <label className="form__label form__label--required">
              {t('localization.Name')}
            </label>
          )}
          <input
            type="text"
            className="material"
            name="contact"
            value={this.state.form.contact}
            onChange={this.handleChange}
            required
          />
        </div>
        <div
          className={`input-container ${this.validateValue('phone') ? 'error' : undefined}`}
        >
          {this.validateValue('phone') ? (
            <label className="form__label form__label--required">
              {t('localization.Invalid-phone')}
            </label>
          ) : (
            <label className="form__label form__label--required">
              {t('localization.Phone')}
            </label>
          )}
          <input
            type="tel"
            className="material"
            name="phone"
            pattern="^[ 0-9]+$"
            value={this.state.form.phone}
            onKeyDown={this.handleChange}
            onInput={this.handleChange}
            onPaste={this.handleChange}
            required
          />
        </div>
        <div
          className={`input-container ${this.validateValue('residence_city') ? 'error' : undefined}`}
        >
          {this.validateValue('residence_city') ? (
            <label className="form__label form__label--required">
              {t('VacanciesModal.invalid_city')}
            </label>
          ) : (
            <label className="form__label form__label--required">
              {t('VacanciesModal.city')}
            </label>
          )}
          <input
            type="text"
            className="material"
            name="residence_city"
            value={this.state.form.residence_city}
            onChange={this.handleChange}
            required
          />
        </div>
        <div
          className={`input-container ${this.validateValue('email') ? 'error' : undefined}`}
        >
          <label className="form__label form__label">E-mail</label>
          <input
            type="email"
            className="material"
            name="email"
            value={this.state.form.email}
            onChange={this.handleChange}
          />
        </div>
        <div
          className={`input-container ${this.validateValue('age') ? 'error' : undefined}`}
        >
          <label className="form__label form__label">
            {t('VacanciesModal.Age')}
          </label>
          <input
            type="text"
            className="material"
            name="age"
            value={this.state.form.age}
            onKeyDown={this.handleChange}
            onChange={this.handleChange}
          />
        </div>
        {!isUkraine && (
          <div
            className={`input-container ${this.validateValue('nationality') ? 'error' : undefined}`}
          >
            <label className="form__label">
              {t('VacanciesModal.Nationality')}
            </label>
            <input
              type="text"
              className="material"
              name="nationality"
              value={this.state.form.nationality}
              onChange={this.handleChange}
            />
          </div>
        )}
        <div
          className={`input-container input-container--textarea ${
            this.validateValue('about') ? 'error' : undefined }`}
        >
          <label className="form__label form__label">
            {t('VacanciesModal.Message')}
          </label>
          <span className="form__text form__text--lighter">
            {t('VacanciesModal.Tell-about-yourself')}
          </span>
          <Textarea
            name="about"
            className="form__textarea"
            value={this.state.form.about}
            onChange={this.handleChange}
            required
          />
        </div>
        <div className="auth-register__checkbox vacancies-modal__checkbox">
          <div className="checkbox-box">
            <div
              className={clsx('checkbox-box__checkbox-container', {
                error: !checkedInfo,
                ua: isUkraine,
              })}
            >
              <input
                type="checkbox"
                id="info"
                name="info_personal"
                checked={checkedInfo}
                onChange={this.toggleShow}
              />
              <label htmlFor="info"> {t('VacanciesModal.info')}</label>
            </div>
          </div>
        </div>
        <div className="input-group input-group__btn-container">
          <Button
            className="sw-button"
            type={checkedInfo ? 'submit' : 'button'}
            disabled={!checkedInfo}
            onClick={checkedInfo ? this.handleSubmit : undefined}
          >
            {t('localization.sendResume')}
          </Button>
        </div>
      </form>
    );
  }

  render() {
    const { sended } = this.state;
    const { t } = this.props;
    return (
      <Modal
        isOpen={this.state.modalIsOpen}
        onRequestClose={this.closeModal}
        style={customStyles}
        contentLabel="Vacancies form"
      >
        {sended ? (
          <div className="input-container form__title form__title--success">
            {t('VacanciesModal.Success')}
          </div>
        ) : (
          this.renderForm()
        )}
        <div
          className="close-modal-btn"
          data-action="close"
          onClick={this.closeModal}
        />
      </Modal>
    );
  }
}

export default withTranslation()(withCheckCountries(VacanciesModal));
