import { RoutesWithSubRoutes } from '@shared/lib/routing';
import { StoreProvider } from '@app/providers/store-provider';
import { SwRegister } from '@app/sw-register';
import routes from './routes';
import { ErrorCatcher } from './error-boundary';

function App() {
  return (
    <ErrorCatcher>
      <StoreProvider>
        <RoutesWithSubRoutes routes={routes} />
        <SwRegister />
      </StoreProvider>
    </ErrorCatcher>
  );
}

export default App;
