import { memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IAction, IQtyState } from 'types/cartTypes';
import Accordion from '@shared/ui/Accordion/Accordion';
import { clsx } from 'clsx';
import CloseSVG from 'assets/images/icon/cancelNoFill.svg?url';
import { SmartLink } from '@shared/lib/routing';
import SelectGiftCard from '../SelectGiftCard/SelectGiftCard';
import styles from './cartPromotion.module.scss';

const initialQtyState = (actionsItem: IAction) => {
  const state: IQtyState = {};
  actionsItem.action.gift_select.forEach((giftArray, index) => {
    state[index] = 0;
  });
  if (actionsItem.action.gift_select.length) state[0] = 1;
  return state;
};

const getActionQtyUsed = (qtyState: IQtyState) =>
  Object.keys(qtyState).reduce(
    (acc, currentValue) => acc + qtyState[+currentValue],
    0,
  );

interface ICartPromotion {
  stock: IAction;
  isHidden: boolean;
  isDisabled: boolean;
  qtyStateInCart: IQtyState;
  isExact: boolean;
  count: number;
  selected?: number; // index of selected with gift_multiply === 1
  onClickSelect?: (actionId: number, index: number) => void;
  onClickDisable?: (actionId: number) => void;
  onClickHide: (actionId: number) => void;
  changeQtyGlobal?: (id: number, qtyState: IQtyState) => void;
}

function CartPromotion({
  stock,
  isHidden = false,
  isDisabled = false,
  qtyStateInCart,
  isExact,
  count,
  selected,
  onClickSelect,
  onClickDisable,
  changeQtyGlobal,
  onClickHide,
}: ICartPromotion) {
  const { t } = useTranslation();
  const {
    id,
    title,
    action: { gift_select: actionGiftSelect = [] },
    link = '',
    gift_multiply: actionQty,
  } = stock;

  const [qtyState, setQtyState] = useState(
    Object.keys(qtyStateInCart) ? qtyStateInCart : initialQtyState(stock),
  );

  const [isHiddenState, setIsHiddenState] = useState(isHidden);

  const checkIfMoreAvaliable = useCallback(() => {
    if (actionQty < 2) return;
    if (qtyState) {
      const nowSelected = getActionQtyUsed(qtyState);
      return actionQty > nowSelected;
    }
    return true;
  }, [actionQty, qtyState]);

  const [moreAvaliable, setMoreAvaliable] = useState(checkIfMoreAvaliable());

  const updateQtyState = useCallback(
    (actionQty: number) => {
      let diff = getActionQtyUsed(qtyState) - actionQty;
      if (diff > 0) {
        const iterArray = Object.keys(qtyState).reverse();
        const newState = { ...qtyState };
        iterArray.forEach((key) => {
          if (diff === 0) return;
          if (qtyState[+key] > 0) {
            const canRemove = qtyState[+key];
            if (canRemove >= diff) {
              newState[+key] -= diff;
              diff = 0;
              return;
            }
            newState[+key] = 0;
            diff -= qtyState[+key];
          }
        });
        setQtyState(newState);
      }
    },
    [qtyState],
  );

  const chandeQty = useCallback(
    (index: number, value: number) => {
      if (!changeQtyGlobal) return;
      const newQtyState = { ...qtyState };
      newQtyState[index] = newQtyState[index]
        ? newQtyState[index] + value
        : value;
      setQtyState(newQtyState);
      changeQtyGlobal(id, newQtyState);
    },
    [changeQtyGlobal, id, qtyState],
  );

  const onClickHideHandler = (actionId: number) => {
    onClickHide(actionId);
  };

  useEffect(() => {
    if (selected && selected > actionGiftSelect.length && onClickSelect)
      onClickSelect(id, 0);

    if (!actionQty || actionQty < 2) return;
    if (Object.keys(qtyState).length) {
      setMoreAvaliable(checkIfMoreAvaliable());
      return;
    }
    if (selected !== undefined) {
      chandeQty(selected, 1);
      setMoreAvaliable(checkIfMoreAvaliable());
    }
  }, [
    actionQty,
    qtyState,
    selected,
    checkIfMoreAvaliable,
    actionGiftSelect,
    chandeQty,
    onClickSelect,
    id,
  ]);

  useEffect(() => {
    updateQtyState(actionQty);
  }, [actionQty, updateQtyState]);

  const hideHandler = () => {
    onClickHideHandler(id);
    setIsHiddenState(!isHiddenState);
  };
  const isDiscount = !actionGiftSelect.length;
  const canDisable = !!onClickDisable && isExact && !isDiscount;

  const isSelect = actionGiftSelect?.length > 1;
  const isSelectWithCounter = isSelect && actionQty > 1;

  const onClickDisableHandler = (actionId: number) => {
    if (typeof onClickDisable === 'function') {
      onClickDisable(actionId);
    }
  };

  const giftClass = clsx(styles.giftItem, { [styles.disabled]: isDisabled });
  const giftLinkClass = clsx(styles.giftLink, styles.colorGray, {
    [styles.noClickable]: isDiscount,
    [styles.arrow]: !isDiscount,
    [styles.arrowColorGray]: !isDiscount,
    [styles.arrowDown]: !isHidden,
    [styles.arrowDisabled]: isDisabled,
  });

  return (
    <div className={giftClass}>
      <div className={styles.gfitDropdown}>
        <div>
          <div className={styles.giftHeader}>
            <strong className={styles.giftNumber}>{count}</strong>
            <div className={styles.giftLinkConteiner} onClick={hideHandler}>
              <p className={giftLinkClass}>{title}</p>
            </div>
            {canDisable && (
              <div
                onClick={() => onClickDisableHandler(id)}
                role="button"
                tabIndex={0}
                className={styles.giftButton}
              >
                {isDisabled ? (
                  <button type="button" className={styles.addButton}>
                    {t('CartGifts.add')}
                  </button>
                ) : (
                  <button type="button" className={styles.closeButton}>
                    <img src={CloseSVG} alt="close" />
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
        {actionGiftSelect.length > 0 && (
          <Accordion show={!isHiddenState}>
            <ul className={styles.dropdownBody}>
              {actionGiftSelect.map((giftOption, index) => (
                <SelectGiftCard
                  key={`select-gift-${index}`}
                  giftOption={giftOption}
                  index={index}
                  stockId={stock.id}
                  isSelect={isSelect}
                  isExact={isExact}
                  qtyState={qtyState[index] || 0}
                  isChecked={selected === index}
                  moreAvaliable={moreAvaliable || false}
                  actionQty={actionQty}
                  chandeQty={chandeQty}
                  onClickSelect={(actionId: number, index: number) => {
                    if (isSelectWithCounter || !onClickSelect) return;
                    onClickSelect(actionId, index);
                  }}
                />
              ))}
            </ul>
            {link && (
              <div className={styles.bodyLink}>
                <SmartLink className={styles.giftLink} href={link}>
                  {t('CartGifts.more_info')}
                </SmartLink>
              </div>
            )}
          </Accordion>
        )}
      </div>
    </div>
  );
}

export default memo(CartPromotion);
