import './VacancyCardMobile.scss';
import autobind from 'autobind-decorator';
import { Component } from 'react';
import { modalEmitter } from 'modules/event-emitters';
import { useTranslation } from 'react-i18next';
import { Button } from '@shared/ui/button';
import { ExternalImage } from '@shared/ui/image';
import { SmartLink } from '@shared/lib/routing';
import { useSelectCountryMainDomain } from '@entities/countries';

class VacancyCardMobile extends Component {
  @autobind
  showVacanciesFormModal(event) {
    event.stopPropagation();
    const {
      vacancy: { id },
    } = this.props;
    modalEmitter.emit('Vacancies-mobile.Modal.Open', id);
  }

  render() {
    const { vacancy, mainDomain, t } = this.props;
    const { text_id, title, city_name, image, salary, schedule, experience } =
      vacancy;

    return (
      <div className="vacancy__card">
        <SmartLink
          href={`/vakansii/${text_id}`}
          className="vacancy__card__front"
        >
          <div className="vacancy__card__head">
            <h2 className="vacancy__card__title">{title}</h2>
            <div className="vacancy__card__city"> {city_name}</div>
          </div>
          <ExternalImage
            mainDomain={mainDomain}
            src={image}
            width={450}
            height={300}
            className="vacancy__card__image"
          />
          <div className="vacancy__card__info">
            <div className="vacancy__card__info__row">
              <div className="vacancy__card__info__row__option">
                {t('VacancyCard.Salary')}:
              </div>
              <div className="vacancy__card__info__row__value">{salary}</div>
            </div>
            <div className="vacancy__card__info__row">
              <div className="vacancy__card__info__row__option">
                {t('VacancyCard.Schedule')}:
              </div>
              <div className="vacancy__card__info__row__value">{schedule}</div>
            </div>
            <div className="vacancy__card__info__row">
              <div className="vacancy__card__info__row__option">
                {t('VacancyCard.Experience')}:
              </div>
              <div className="vacancy__card__info__row__value">
                {experience}
              </div>
            </div>
          </div>
        </SmartLink>
        <div className="vacancy__card__button_container">
          <Button
            className="vacancy__card__button"
            onClick={this.showVacanciesFormModal}
          >
            {t('localization.sendResume')}
          </Button>
        </div>
      </div>
    );
  }
}

function EnrichedVacancyCardMobile(props) {
  const mainDomain = useSelectCountryMainDomain();
  const { t } = useTranslation();
  return <VacancyCardMobile mainDomain={mainDomain} t={t} {...props} />;
}
export default EnrichedVacancyCardMobile;
