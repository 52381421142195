import { useSelectCountry } from './selectors';

export const checkCountries = (alpha2: string) => ({
  isBelarus: alpha2 === 'BY',
  isRussia: alpha2 === 'RU',
  isMontenegro: alpha2 === 'ME',
  isArmenia: alpha2 === 'HY',
  isUkraine: alpha2 === 'UA' || alpha2 === 'PL', // sushistory
});

export const useCheckCountries = () => {
  const { alpha2 } = useSelectCountry();
  return checkCountries(alpha2);
};

export const withCheckCountries = <T extends object>(
  Component: React.ComponentType<
    React.PropsWithChildren<T & ReturnType<typeof useCheckCountries>>
  >,
) => {
  function CheckCountriesWrapper(props: T) {
    const selectedCountry = useCheckCountries();
    return <Component {...props} {...selectedCountry} />;
  }
  return CheckCountriesWrapper;
};
