import { useTranslation } from 'react-i18next';
import styles from './closeButton.module.scss';

interface ICloseButton
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {}

// do not use className, adjust props if necessary
export function CloseButton({ className: _, ...props }: ICloseButton) {
  const { t } = useTranslation();
  return (
    <button
      type="button"
      className={styles.root}
      aria-label={t('localization.closeAction')}
      {...props}
    />
  );
}
