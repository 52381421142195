import { useCurrency, useSelectCountryMainDomain } from '@entities/countries';
import { DEPRECATED_CUSTOM_HELMET } from '../../Components/CustomHelmet';

function SingleProductHelmet({
  product: { title, text_id, weight, price, images = [], composition = [] },
  pathname,
}) {
  const seoCompos = composition.map(({ title }) => title).join(',');
  const priceCurrency = useCurrency();
  const mainDomain = useSelectCountryMainDomain();
  const path = images[0] && images[0].filename;
  const image = new URL(`/img/${path}`, `https://${mainDomain}`).toString();
  const url = new URL(pathname, `https://${mainDomain}`).toString();
  const seoScript = {
    '@context': 'http://schema.org/',
    '@type': 'Product',
    description: seoCompos,
    name: title,
    alternateName: text_id,
    image,
    weight: {
      '@type': 'QuantitativeValue',
      value: weight, // вес
    },
    offers: {
      '@type': 'Offer',
      availability: 'http://schema.org/InStock',
      url,
      price,
      priceCurrency,
    },
  };

  return (
    <DEPRECATED_CUSTOM_HELMET
      title={title}
      type="object"
      image={image}
      ogTitle={title}
      ogDescription={seoCompos}
      script={[
        {
          type: 'application/ld+json',
          innerHTML: JSON.stringify(seoScript),
        },
      ]}
    />
  );
}

export default SingleProductHelmet;
