import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useGetModalPageDataQuery } from '../api/modalPageApi';
import { useModalPageModal } from './use-modal-page-modal';

export function ModalPageInfo({
  cityTextId,
  mainDomain,
}: {
  cityTextId: string;
  mainDomain: string;
}) {
  const { i18n } = useTranslation();
  const { show } = useModalPageModal();
  const location = useLocation();

  const { data } = useGetModalPageDataQuery({
    url: location.pathname.replace(`/${cityTextId}`, ''),
    city: cityTextId,
    lang: i18n.language,
  });

  const { data: modalData } = data || {};

  useEffect(() => {
    if (modalData?.modal && Object.keys(modalData.modal).length) {
      show(modalData.modal, mainDomain);
    }
  }, [mainDomain, modalData, show]);

  return null;
}
