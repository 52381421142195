export const enum BlockNames {
  slider = 'slider',
  stocks = 'stocks',
  promos = 'promos',
  news = 'news',
  articles = 'articles',
}

export interface IMainPageItem {
  order: number;
  active: boolean;
  name: BlockNames;
}
