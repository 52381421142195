const getActions = (ids, cart) => {
  const { actions } = cart;
  const actionsArr = [];

  ids.forEach((id) => {
    actions.forEach(({ action: { gift_select = [] } = {}, id: actionId }) => {
      const finded = gift_select.find((gift) =>
        gift.some(({ product_id }) => +product_id === +id),
      );

      if (finded) {
        actionsArr.push(actionId);
      }
    });
  });
  return actionsArr;
};

const getUserGifts = (ids, userGifts) =>
  userGifts.reduce((accum, { product_id, maxQty, qty }) => {
    if (ids.find((id) => id === product_id))
      return [...accum, { product_id, maxQty, qty: 0 }];
    return [...accum, { product_id, maxQty, qty }];
  }, []);

export { getActions, getUserGifts };
