import globalStore from 'modules/global-store';
import { ICountry } from './types';

export const useSelectCountry = (): ICountry => globalStore.get('country');

export const useSelectCountryCurrency = () => {
  const country = useSelectCountry();
  return country.currency;
};

export const useSelectCountryMainDomain = () => {
  const country = useSelectCountry();
  return country.main_domain;
};
