import { IRoute } from '@shared/lib/routing';
import { StaticPageLoader } from '@pages/static-page';
import Vacancies from 'frontend/Pages/Vacancies/vacancies-page/ui/Vacancies';
import loadable from '@loadable/component';
import { AboutPageLoader } from '@pages/about';
import { FranchisePageLoader } from '@pages/franchise-page';
import { OneNewsPageLoader } from '@pages/one-news-page';
import { storesPageLoader } from '@pages/stores';
import { MenuLoader } from '@pages/menu/api/menuApi';
import { oneArticlePageLoader } from '@pages/one-article-page';
import { vacancyLoader } from '@pages/vacancy';
import { argumentsPageLoader } from '@pages/arguments';
import { deliveryPageLoader } from '@pages/delivery';
import { onePromotionPageLoader } from '@pages/one-promotion-page';
import { mainPageLoader } from '@pages/main-page';
import { orderFeedbackLoader } from '@pages/order-feedback';
import ProductList from '../frontend/Pages/ProductList/ProductList';
import ProductSingle from '../frontend/Pages/ProductSingle/ProductSingle';
import Reviews from '../frontend/Pages/Reviews/Reviews';
import NewsAll from '../frontend/Pages/News/News';
import Articles from '../frontend/Pages/Articles/Articles';
import Stocks from '../frontend/Pages/Stocks/Stocks';
import QualityPage from '../frontend/Pages/Quality/QualityPage';
import Cart from '../frontend/Pages/cart/Cart';
import BlockMap from '../frontend/Components/NewCartOrder/Components/BlockMap/BlockMap';
import ErrorPage from '../frontend/Pages/ErrorPage/ErrorPage';
import SubscribePage from '../frontend/Pages/Subscribe/Subscribe';
import OrderPage from '../frontend/Pages/Order/Info/OrderInfoPage';
import Profile from '../frontend/Pages/Profile/Profile';
import OrdersPage from '../frontend/Pages/Profile/orders/Orders';
import FavoritesPage from '../frontend/Pages/Profile/favorites-page/FavoritesPage';
import ProfilePage from '../frontend/Pages/Profile/Profile/Profile';
import OrganizationContactInformation from '../frontend/Pages/OrganizationContactInformation/OrganizationContactInformation';
import { cityLayoutLoader, CityLayout } from './city-layout';

const DeliveryOrderingForm = loadable(
  () => import('@pages/delivery-ordering'),
  {
    resolveComponent: ({ DeliveryOrderingForm: P }) => P,
  },
);

const PickupOrderingForm = loadable(() => import('@pages/pickup-ordering'), {
  resolveComponent: ({ PickupOrderingForm: P }) => P,
});

const StaticPage = loadable(() => import('@pages/static-page'), {
  resolveComponent: ({ StaticPage: P }) => P,
});

const About = loadable(() => import('@pages/about'), {
  resolveComponent: ({ About: P }) => P,
});

const FranchisePage = loadable(() => import('@pages/franchise-page'), {
  resolveComponent: ({ FranchisePage: P }) => P,
});

const OneNewsPage = loadable(() => import('@pages/one-news-page'), {
  resolveComponent: ({ OneNewsPage: P }) => P,
});

const OneArticlePage = loadable(() => import('@pages/one-article-page'), {
  resolveComponent: ({ OneArticlePage: P }) => P,
});

const OnePromotionPage = loadable(() => import('@pages/one-promotion-page'), {
  resolveComponent: ({ OnePromotionPage: P }) => P,
});

const Menu = loadable(() => import('@pages/menu'), {
  resolveComponent: ({ Menu: P }) => P,
});

const Vacancy = loadable(() => import('@pages/vacancy'), {
  resolveComponent: ({ Vacancy: P }) => P,
});
const Arguments = loadable(() => import('@pages/arguments'), {
  resolveComponent: ({ Arguments: P }) => P,
});

const Stores = loadable(() => import('@pages/stores'), {
  resolveComponent: ({ Stores: P }) => P,
});

const Delivery = loadable(() => import('@pages/delivery'), {
  resolveComponent: ({ Delivery: P }) => P,
});

const MainPage = loadable(() => import('@pages/main-page'), {
  resolveComponent: ({ MainPage: P }) => P,
});

const OrderFeedbackPage = loadable(() => import('@pages/order-feedback'), {
  resolveComponent: ({ OrderFeedbackPage: P }) => P,
});

export default [
  {
    path: '/:city',
    component: CityLayout, // without page-container
    loader: cityLayoutLoader,
    routes: [
      {
        path: '/:city/',
        exact: true,
        component: MainPage,
        loader: mainPageLoader,
      },
      {
        path: '/:city/about',
        exact: true,
        component: About,
        loader: AboutPageLoader,
      },
      {
        path: '/:city/arguments',
        exact: true,
        component: Arguments,
        loader: argumentsPageLoader,
      },
      {
        path: '/:city/franchise',
        exact: true,
        component: FranchisePage,
        loader: FranchisePageLoader,
      },
      { path: '/:city/quality', exact: true, component: QualityPage }, // method /review/submit_quality don`t work
      { path: '/:city/reviews/:page?', component: Reviews },
      {
        path: '/:city/delivery',
        exact: true,
        component: Delivery,
        loader: deliveryPageLoader,
      },
      {
        path: '/:city/addresses',
        exact: true,
        component: Stores,
        loader: storesPageLoader,
      },

      {
        path: '/:city/menu/:menu/:product',
        exact: true,
        component: ProductSingle,
      },

      { path: '/:city/menu/:menu', exact: true, component: ProductList },
      {
        path: '/:city/menu',
        exact: true,
        component: Menu,
        loader: MenuLoader,
      },

      {
        path: '/:city/news/:news_item',
        exact: true,
        component: OneNewsPage,
        loader: OneNewsPageLoader,
      },
      { path: '/:city/news', exact: true, component: NewsAll },

      {
        path: '/:city/articles/:article',
        exact: true,
        component: OneArticlePage,
        loader: oneArticlePageLoader,
      },
      { path: '/:city/articles', exact: true, component: Articles },
      {
        path: '/:city/vakansii/:vacancies_item',
        exact: true,
        component: Vacancy,
        loader: vacancyLoader,
      },
      { path: '/:city/vakansii', exact: true, component: Vacancies },

      {
        path: '/:city/akcii/:stock',
        exact: true,
        component: OnePromotionPage,
        loader: onePromotionPageLoader,
      },
      { path: '/:city/akcii', exact: true, component: Stocks },
      {
        path: '/:city/page/:pageId',
        exact: true,
        component: StaticPage,
        loader: StaticPageLoader,
      },
      { path: '/:city/subscribe/email/:action', component: SubscribePage },

      // order
      {
        path: '/:city/order/feedback/:order_hash/:order_salt',
        exact: true,
        component: OrderFeedbackPage,
        loader: orderFeedbackLoader,
      },
      {
        path: '/:city/order/:order_hash/:order_salt',
        exact: true,
        component: OrderPage,
      },

      // profile
      {
        path: '/:city/profile',
        component: Profile,
        routes: [
          { path: '/:city/profile/orders', exact: true, component: OrdersPage },
          {
            path: '/:city/profile/favorites',
            exact: true,
            component: FavoritesPage,
          },
          { path: '/:city/profile', exact: true, component: ProfilePage },
        ],
      },

      // cart
      {
        path: '/:city/cart',
        component: Cart,
        routes: [
          {
            path: '/:city/cart/delivery',
            exact: true,
            component: DeliveryOrderingForm,
          },
          { path: '/:city/cart/pickup', exact: true, component: BlockMap },
          {
            path: '/:city/cart/pickup/:store_coords',
            exact: true,
            component: PickupOrderingForm,
          },
        ],
      },

      // organization contact information
      {
        path: '/:city/dannye-prodavca',
        exact: true,
        component: OrganizationContactInformation,
      },

      // don`t touch pls, must always be the lowest
      { component: ErrorPage },
    ],
  },
] as IRoute[];
