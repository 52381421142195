import { CartLink } from '../cartLink/CartLink';

interface IMobileProps {
  mobileSidebarOpener: JSX.Element;
  decoration: { left?: JSX.Element; right?: JSX.Element };
  logo: JSX.Element;
}

function Mobile({ mobileSidebarOpener, decoration, logo }: IMobileProps) {
  return (
    <div
      className="flex h-(--header-height-mobile) items-center justify-between
        *:first:aspect-square *:first:h-full *:last:aspect-square *:last:h-full"
    >
      {mobileSidebarOpener}
      {decoration.left}
      {logo}
      {decoration.right}
      <CartLink isMobile />
    </div>
  );
}

export { Mobile };
