import { Component, Fragment } from 'react';
import VacancyCardMobile from 'frontend/Pages/Vacancies/vacancy-card/VacancyCardMobile';
import { DEPRECATED_CUSTOM_HELMET } from 'frontend/Components/CustomHelmet';
import globalStore from 'modules/global-store';
import VacanciesModalMobile from 'frontend/Components/Vacancies/VacanciesModal/VacanciesModalMobile';
import { DEPRECATED_CUSTOM_TEXT } from 'frontend/Components/CustomText/CustomText';
import { PageTitle } from '@shared/ui/titles';
import VacancyCommentLine from 'frontend/Pages/Vacancies/vacancy-comment-line/VacancyCommentLine';
import VacancyCard from 'frontend/Pages/Vacancies/vacancy-card/VacancyCard';
import { Breadcrumbs } from '@shared/ui/breadcrumbs';
import { VacanciesInfo } from 'frontend/Pages/Vacancies/vacancy-info';
import { VacancyContactsLine } from 'frontend/Pages/Vacancies/vacancy-contact-line/VacancyContactsLine';
import VacanciesModal from 'frontend/Components/Vacancies/VacanciesModal/VacanciesModal';
import { clsx } from 'clsx';
import ssr from 'modules/decorators/ssr';
import api from 'modules/helpers/api';
import { vacanciesEmitter } from 'modules/event-emitters';
import { PageContainer } from '@shared/ui/content-containers';
import { DEPRECATED_BANNER } from '@widgets/seoPage';
import { VacancyVideo } from '../../vacancy-video';
import styles from './vacancies.module.scss';

class Vacancies extends Component {
  static LOADED_KEY = 'vacancies_loaded';

  static initialData = (fetch, params, store) =>
    fetch('vacancies')
      .then((vacancies) => store.set('vacancies', vacancies))
      .catch((e) => {
        console.error('Vacancies', e);
        return [];
      });

  constructor(props) {
    super(props);
    this.state = {
      isShowOverlay: false,
      vacancies: globalStore.get('vacancies'),
    };
  }

  componentDidMount() {
    if (globalStore.get(Vacancies.LOADED_KEY)) {
      globalStore.unlink(Vacancies.LOADED_KEY);
    } else {
      Vacancies.initialData(api, {}, globalStore).then(() => {
        this.setState({
          vacancies: globalStore.get('vacancies', []),
        });
      });
    }

    vacanciesEmitter.addListener(
      'Vacancies.Card.showOverlay',
      this.showOverlay,
    );
    vacanciesEmitter.addListener(
      'Vacancies.Card.hideOverlay',
      this.hideOverlay,
    );
  }

  componentWillUnmount() {
    vacanciesEmitter.removeListener(
      'Vacancies.Card.showOverlay',
      this.showOverlay,
    );
    vacanciesEmitter.removeListener(
      'Vacancies.Card.hideOverlay',
      this.hideOverlay,
    );
  }

  showOverlay = () => {
    this.setState({
      isShowOverlay: true,
    });
  };

  hideOverlay = () => {
    this.setState({
      isShowOverlay: false,
    });

    vacanciesEmitter.emit('Vacancies.Card.Unrotate');
  };

  render() {
    const { vacancy_email } = globalStore.get('current_city');
    const { vacancies, isShowOverlay } = this.state;

    if (!vacancies) return null;

    const { t } = this.props;

    const vacanciesFirst = vacancies.slice(0, 3);
    const vacanciesLast = vacancies.slice(3);

    const contentFirst = vacanciesFirst.map((vacancy, index) => {
      const { id } = vacancy;
      return (
        <Fragment key={id}>
          <div className="hidden lg:block">
            <VacancyCard
              vacancyEmail={vacancy_email}
              vacancy={vacancy}
              vacancies={vacancies}
              index={index}
            />
          </div>
          <div className="block lg:hidden">
            <VacancyCardMobile
              vacancyEmail={vacancy_email}
              vacancy={vacancy}
              vacancies={vacancies}
            />
          </div>
        </Fragment>
      );
    });

    const contentLast = vacanciesLast.map((vacancy, index) => {
      const { id } = vacancy;

      return (
        <Fragment key={id}>
          <div className="hidden lg:block">
            <VacancyCard
              vacancyEmail={vacancy_email}
              vacancy={vacancy}
              vacancies={[...vacancies.slice(3), ...vacancies.slice(0, 3)]}
              index={index}
            />
          </div>
          <div className="block lg:hidden">
            <VacancyCardMobile
              vacancyEmail={vacancy_email}
              vacancy={vacancy}
              vacancies={vacancies}
            />
          </div>
        </Fragment>
      );
    });

    const links = [
      {
        text: t('Vacancies.vacanciesTitle'),
      },
    ];

    const overlayStyles = clsx(styles.overlay, {
      [styles.showOverlay]: isShowOverlay,
    });

    return (
      <>
        <DEPRECATED_BANNER pathname="/vakansii" />
        <DEPRECATED_CUSTOM_HELMET
          title={t('Vacancies.vacanciesTitle')}
          type="article"
        />
        <PageContainer>
          <Breadcrumbs links={links} />
          <PageTitle isCenterOnMobile>
            {t('Vacancies.vacanciesTitle')}
          </PageTitle>
          <div className={styles.listsWrapper}>
            <div className={styles.list}>{contentFirst}</div>
            <VacancyCommentLine />
            <div className={styles.list}>{contentLast}</div>
          </div>
          <div
            className={overlayStyles}
            onClick={() => {
              this.setState({
                isShowOverlay: false,
              });
              vacanciesEmitter.emit('Vacancies.Card.Unrotate');
            }}
            role="none"
          />
          <VacanciesInfo />
          <VacancyVideo />
          <VacancyContactsLine vacancyEmail={vacancy_email} />
          <VacanciesModal />
          <VacanciesModalMobile />
          <DEPRECATED_CUSTOM_TEXT className="custom-text-wrap" />
        </PageContainer>
      </>
    );
  }
}

export default ssr(Vacancies);
