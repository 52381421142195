/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback } from 'react';

function debounce<K extends (...params: any[]) => unknown>(
  func: K,
  delay: number,
) {
  let timeoutId: ReturnType<typeof setTimeout>;
  return (...params: Parameters<K>) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => func(...params), delay);
  };
}

export const useDebounce = <K extends (...params: any[]) => unknown>(
  func: K,
  delay: number,
  debounceDeps: unknown[] = [],
) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFunc = useCallback(debounce(func, delay), debounceDeps);

  return debouncedFunc;
};
