import { useTranslation } from 'react-i18next';

interface IAvailableMethodsOfReceiving {
  is_delivery: boolean;
  is_pickup: boolean;
  decoration: { additional_text_color?: string };
  isBig?: boolean;
  addComma?: boolean;
}
export function AvailableMethodsOfReceiving({
  is_delivery,
  is_pickup,
  decoration: { additional_text_color: textColor = '' } = {},
  isBig = false,
  addComma = false,
}: IAvailableMethodsOfReceiving) {
  const { t } = useTranslation();

  if (is_delivery && is_pickup) return;
  let text = t('Card.DisableBoth');
  text = is_delivery ? t('Card.Delivery only') : t('Card.AvailableOnlyForEx');

  const textSize = isBig ? 'text-xl lg:text-base' : 'text-sm';
  return (
    <span
      className={`block ${textSize} font-light text-(--common-2) lowercase sm:inline`}
      style={{ color: textColor }}
    >
      {text}
      {addComma && <span className="hidden sm:inline">, </span>}{' '}
    </span>
  );
}
