import { ExternalImage } from '@shared/ui/image';
import { useTranslation } from 'react-i18next';

import { clsx } from 'clsx';
import { useImageSrc } from '@shared/ui/image/image-src';
import { useSelectCountryMainDomain } from '@entities/countries';
import { useCityTextIdParam } from '@shared/lib/routing';
import { IPromotion } from '@entities/promotion';
import styles from './cardStock.module.scss';

interface ICardStock {
  data: IPromotion;
}

function CardStock({ data }: ICardStock) {
  const {
    text_id,
    images,
    title,
    has_static_page: hasStaticPage,
    link,
    type,
  } = data;
  const { t } = useTranslation();
  const cityTextId = useCityTextIdParam();
  const mainDomain = useSelectCountryMainDomain();
  const imageName = images[0] ? images[0].filename : '';
  const smallImageSizes = '574x575';
  const bigImageSizes = '1570x480';
  const isImageBig = +type === 2;
  const imagePath = `${imageName}/${
    isImageBig ? bigImageSizes : smallImageSizes
  }`;

  let stockLink: string | undefined;

  if (link) {
    stockLink = link;
  } else if (hasStaticPage) {
    stockLink = `/${cityTextId}/akcii/${encodeURIComponent(text_id)}`;
  }

  const cardStock = clsx(styles.root, {
    [styles.noLink]: !link,
  });
  const { srcData } = useImageSrc({ path: imagePath, mainDomain });

  return (
    <a
      className={cardStock}
      href={stockLink}
      style={type !== 2 ? { backgroundImage: `url(${srcData.href})` } : {}}
    >
      {type === 2 && (
        <ExternalImage
          mainDomain={mainDomain}
          src={imagePath}
          alt={title || t('CardList.cart_list_item')}
          width={600}
          height={600}
        />
      )}
    </a>
  );
}

export default CardStock;
