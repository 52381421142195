import './AddressEdit.json';
import './AddressDelete.scss';
import { Component } from 'react';
import Modal from 'react-modal';
import autobind from 'autobind-decorator';
import * as session from 'modules/session';
import validators from 'modules/form-validators';
import api from 'modules/helpers/api';
import ResponseError from 'modules/response/error';
import escapeReg from 'modules/helpers/escape-regexp';
import removeUnwantedCharacters from 'modules/helpers/remove-unwanted-characters';
import { Button } from '@shared/ui/button';
import { userProfileEmitter } from 'modules/event-emitters';
import { withTranslation } from 'react-i18next';
import { withBreakpoints } from 'frontend/hocs';
import TextArea from '../../../Elements/Textarea/Textarea';

const validateRules = {
  street: 'string:255',
  house: 'string:30',
  name: 'string:100',
  phone: 'phone',
  city: 'string:255',
};

const customStyles = {
  overlay: {
    zIndex: 20,
    backgroundColor: 'rgba(252, 252, 252, 0.7)',
    overflowY: 'auto',
  },
  content: {
    maxWidth: '790px',
    top: '20%',
    left: '0',
    right: '0',
    bottom: 'auto',
    margin: 'auto',
    overflow: 'visible',
    padding: '18px 60px 36px',
    border: 'none',
    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 16px 60px 20px',
    boxSizing: 'border-box',
    marginBottom: '50px',
  },
};

const mobileStyles = {
  overlay: {
    zIndex: 20,
    backgroundColor: 'rgba(252, 252, 252, 0.7)',
    overflowY: 'auto',
  },
  content: {
    maxWidth: '790px',
    top: '100px',
    left: '0',
    right: '0',
    bottom: 'auto',
    margin: 'auto',
    overflow: 'visible',
    padding: '30px 10px',
    boxSizing: 'border-box',
    marginBottom: '0',
    borderLeft: '0',
    borderRight: '0',
  },
};

class AddressEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      invalidFields: {},
      formSubmitted: false,
      modalIsOpen: false,
      address: {
        street: '',
        house: '',
        building: '',
        entrance: '',
        apartment: '',
        title: '',
      },
      unwantedCharacters: {
        street: `0-9${escapeReg(' .-')}`,
        building: `0-9${escapeReg(' ')}`,
        apartment: '0-9',
        house: `0-9${escapeReg('./-')}`,
      },
    };
  }

  componentDidMount() {
    Modal.setAppElement('body');
    userProfileEmitter.addListener('User.Address.Edit', this.modalShow);
  }

  componentWillUnmount() {
    userProfileEmitter.removeListener('User.Address.Edit', this.modalShow);
  }

  @autobind
  onChangeHandler({ target }) {
    const { address, unwantedCharacters } = this.state;
    const numbersAndSymbols = /[a-zA-Zа-яА-ЯёЁ]|\\W/g;

    switch (target.name) {
      case 'street':
      case 'building':
        if (target.value && target.value[0].match(/ /g))
          target.value = target.value.replace(/ /g, '');
        break;
      case 'entrance':
        target.value = target.value.replace(/[^0-9]/g, '');
        break;
      case 'apartment':
      case 'house':
        if (target.value && target.value[0].match(numbersAndSymbols))
          target.value = target.value.replace(numbersAndSymbols, '');
        break;
      default:
        break;
    }
    if (unwantedCharacters[target.name])
      removeUnwantedCharacters(target, unwantedCharacters[target.name]);

    address[target.name] = target.value;

    this.setState({
      address,
    });
  }

  @autobind
  onSaveHandler() {
    const { invalidFields } = this.state;

    if (Object.keys(invalidFields).length > 0) {
      const inputRequiredAll = document.querySelectorAll('input:invalid');

      if (inputRequiredAll.length) {
        setTimeout(() => {
          inputRequiredAll[0].focus();
        }, 150);
      }
      return this.setState({
        formSubmitted: true,
      });
    }

    this.save();
  }

  @autobind
  entranceHandler(event) {
    const { target } = event;

    target.value = target.value.replace(/[^\d]/g, '');

    return this.onChangeHandler(event);
  }

  @autobind
  modalClose() {
    this.setState({
      modalIsOpen: false,
    });
  }

  @autobind
  modalShow(address) {
    this.setState({
      modalIsOpen: true,
      address: {
        id: address.id || '',
        city: address.city || '',
        street: address.street || '',
        house: address.house || '',
        building: address.building || '',
        entrance: address.entrance || '',
        apartment: address.apartment || '',
        title: address.title || '',
      },
    });
  }

  @autobind
  showError(error) {
    console.error(error);
  }

  @autobind
  apiErrors(error, fields) {
    if (error) {
      this.showError(error);
    }
    if (fields) {
      const { invalidFields } = this.state;
      for (const field in fields) {
        invalidFields[field] = true;
      }
      this.setState({
        invalidFields,
      });
    }
  }

  @autobind
  responseError(error) {
    const responseError = new ResponseError(error);
    if (responseError.isApiError()) {
      return this.apiErrors(
        responseError.getMessage(),
        responseError.getFields(),
      );
    }
    this.showError(responseError.getMessage());
  }

  save() {
    const { address } = this.state;

    api('user.address', address)
      .then(() => {
        userProfileEmitter.emit('AddressEdit.update');

        this.modalClose();
      })
      .catch((error) => {
        this.responseError(error);
      });
  }

  render() {
    const { formSubmitted, invalidFields, address } = this.state;
    const { t, isMobile } = this.props;

    validators.validate(address, validateRules, invalidFields);

    return (
      <Modal
        isOpen={this.state.modalIsOpen}
        onRequestClose={this.modalClose}
        style={isMobile ? mobileStyles : customStyles}
        contentLabel="modal-address-edit"
      >
        <div className="address-edit-modal">
          <div className="address-edit-modal__content">
            <p className="address-edit-modal__content__head">
              {t('AddressEdit.replace address')}
            </p>
          </div>
          <div className="form__content">
            <div className="input-group--50 input-group--flex">
              <div
                className={`input-container input-container--230 required ${
                  formSubmitted && invalidFields.street ? 'error' : '' }`}
              >
                <label>{t('AddressEdit.street')}</label>
                <input
                  type="text"
                  autoComplete="off"
                  name="street"
                  required
                  className={`material ${address.street.length > 0 ? 'active' : ''}`}
                  value={address.street}
                  onChange={this.onChangeHandler}
                />
              </div>

              <div
                className={`input-container input-container--65 required ${
                  formSubmitted && invalidFields.house ? 'error' : '' }`}
              >
                <label>{t('AddressEdit.house')}</label>
                <input
                  type="text"
                  maxLength="6"
                  autoComplete="off"
                  name="house"
                  required
                  className={`material ${address.house.length > 0 ? 'active' : ''}`}
                  value={address.house}
                  onChange={this.onChangeHandler}
                />
              </div>
              <div className="input-container input-container--65">
                <label>{t('AddressEdit.housing')}</label>
                <input
                  type="text"
                  autoComplete="off"
                  maxLength="6"
                  name="building"
                  className={`box ${address.building.length > 0 ? 'active' : ''}`}
                  value={address.building}
                  onChange={this.onChangeHandler}
                />
              </div>
              <div className="input-container input-container--65">
                <label>
                  {session.get('city') === 'spb'
                    ? t('AddressEdit.front_door')
                    : t('AddressEdit.entrance')}
                </label>
                <input
                  type="text"
                  autoComplete="off"
                  maxLength="6"
                  name="entrance"
                  className={`box ${address.entrance.length > 0 ? 'active' : ''}`}
                  value={address.entrance}
                  onChange={this.entranceHandler}
                />
              </div>
              <div className="input-container input-container--65">
                <label>{t('AddressEdit.apartment')}</label>
                <input
                  type="text"
                  autoComplete="off"
                  maxLength="5"
                  name="apartment"
                  className={`box ${address.apartment.length > 0 ? 'active' : ''}`}
                  value={address.apartment}
                  onChange={this.onChangeHandler}
                />
              </div>
            </div>
            <div className="input-container">
              <label> {t('AddressEdit.comment')} </label>
              <TextArea
                name="title"
                onChange={this.onChangeHandler}
                required
                autoComplete="off"
                value={address.title}
              />
            </div>
          </div>
          <Button onClick={this.onSaveHandler}>{t('AddressEdit.save')}</Button>
        </div>

        <div
          className="close-modal-btn close-modal-btn--address-edit"
          data-action="close"
          onClick={this.modalClose}
        />
      </Modal>
    );
  }
}

export default withTranslation()(withBreakpoints(AddressEdit));
