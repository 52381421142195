import { useEffect, useRef, useState, createContext, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { makeRequest } from '@shared/api';

type FetchParams = {
  url: string;
  params: { [key: string]: number | string | number[] };
};
export type FetchLoaderType = <T>(options: FetchParams) => Promise<{ data: T }>;

type ConfigureClientRequestType = (params: FetchParams) => Promise<unknown>;

export interface IServerDataContext {
  staticContext: { pagesData: Record<string, unknown> };
  loader?: (
    configureClientRequest: ConfigureClientRequestType,
    options: {
      params: { [key: string]: number | string | number[] };
      lang?: string;
    },
  ) => Promise<unknown>;
  daddy: string;
}

export const ServerDataContext = createContext<IServerDataContext>({
  staticContext: { pagesData: {} },
  loader: () => Promise.resolve(),
  daddy: '',
});

export const useLoaderData = <D>() => {
  const { staticContext, loader, daddy } = useContext(ServerDataContext);
  const { i18n } = useTranslation();
  const queryParams = useParams();
  const isServer = typeof window === 'undefined';
  const initPagesData = isServer ? staticContext.pagesData : window.PAGES_DATA;

  const initPageData = initPagesData?.[daddy] as D;
  const [pageData, setPageData] = useState(initPageData);
  const [loading, setLoading] = useState(!pageData);
  const fetchNewPageData = useRef(!pageData);

  useEffect(() => {
    if (!loader) return;
    if (fetchNewPageData.current === true) {
      setLoading(true);
      const fetch = ({ url, params }: FetchParams) =>
        makeRequest({
          url: `/endpoint${url}`,
          baseURL: window.location.origin,
          params,
        });
      loader(fetch, { params: queryParams, lang: i18n.language })
        .then((repos) => {
          setPageData(repos as D);
          setLoading(false);
        })
        .catch((e) => {
          console.error(e);
        });
    } else {
      fetchNewPageData.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...Object.values(queryParams), i18n.language]);

  if (loading) {
    return { loading: true as const, pageData: undefined };
  }

  return { loading: false as const, pageData: pageData as D };
};
