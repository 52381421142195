import './QualityPage.scss';
import { Component } from 'react';
import { Helmet } from 'react-helmet-async';
import { withTranslation } from 'react-i18next';
import { LinkButton } from '@shared/ui/button';
import { Breadcrumbs } from '@shared/ui/breadcrumbs';
import { PageContainer } from '@shared/ui/content-containers';
import { DEPRECATED_BANNER } from '@widgets/seoPage';
import Quality from '../../Components/Quality/Quality';
import SuccessResult from '../../Components/SuccessResult/SuccessResult';

class QualityPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSended: false,
    };
  }

  onQualitySuccess = () => {
    this.setState({
      isSended: true,
    });
  };

  render() {
    const { t } = this.props;
    const { isSended } = this.state;

    return (
      <>
        <DEPRECATED_BANNER pathname="/quality" />
        <PageContainer>
          <Breadcrumbs
            links={[
              {
                text: t('Quality.quality'),
              },
            ]}
          />
          <div className="page-quality">
            <Helmet title={t('Quality.quality')} />
            {!isSended ? (
              <Quality onSuccess={this.onQualitySuccess} />
            ) : (
              <>
                <div className="page-quality__thanks">
                  <SuccessResult text={t('QualityPage.review_important')} />
                </div>
                <LinkButton href="/">{t('QualityPage.to_main')}</LinkButton>
              </>
            )}
          </div>
        </PageContainer>
      </>
    );
  }
}

export default withTranslation()(QualityPage);
