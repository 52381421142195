import { Route, Switch, RouteComponentProps } from 'react-router-dom';
import { IServerDataContext, ServerDataContext } from './loader-data';

export interface IRoute {
  path: string;
  component: React.ComponentType<
    React.PropsWithChildren<
      RouteComponentProps & {
        routes?: IRoute[];
      }
    >
  >;
  exact?: boolean;
  routes?: IRoute[];
  loader?: (fetch: unknown, params: unknown) => Promise<unknown>;
}
export function RoutesWithSubRoutes({ routes }: { routes: IRoute[] }) {
  const getContextValue = (params: IServerDataContext) => ({
    ...params,
  });

  return (
    <Switch>
      {routes.map((route, i) => (
        <Route
          // eslint-disable-next-line react/no-array-index-key
          key={i}
          path={route.path}
          exact={route.exact}
          render={(props) => (
            // pass the sub-routes down to keep nesting
            <ServerDataContext.Provider
              value={getContextValue({
                staticContext:
                  props.staticContext as IServerDataContext['staticContext'],
                loader: route.loader,
                daddy: props.match.url,
              })}
            >
              <route.component {...props} routes={route.routes} />
            </ServerDataContext.Provider>
          )}
        />
      ))}
    </Switch>
  );
}
