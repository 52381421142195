import { baseApi } from '@shared/api';
import * as session from 'modules/session';
import { FetchBaseQueryMeta } from '@reduxjs/toolkit/query';
import { IUserAddress, IUserOrder, IUser, IUserUpdate } from '../model/types';

const userUpdateResponseHandling = (
  _resp: { data: { message: string } },
  _meta: FetchBaseQueryMeta | undefined,
  args: Partial<IUserUpdate>,
) => {
  const userBefore = session.get('user');
  const user = { ...userBefore, ...args };
  session.set('user', user);
  return user;
};

export const userApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getUserAddresses: build.query<
      { data: { addresses: IUserAddress[] } },
      { city: string; lang: string }
    >({
      query: (query) => ({
        url: `/endpoint/user/addresses`,
        method: 'GET',
        params: query,
        headers: { authorization: 'true' },
      }),
    }),
    getUserOrders: build.query<
      { data: { orders: IUserOrder[] } },
      { lang: string }
    >({
      query: (params) => ({
        url: '/endpoint/order2/get_orders',
        method: 'GET',
        params,
        headers: { authorization: 'true' },
      }),
    }),
    updateUser: build.mutation<{ data: { user: IUser } }, Partial<IUserUpdate>>(
      {
        query: (params) => ({
          url: '/endpoint/user/update',
          method: 'PUT',
          params,
          headers: { authorization: 'true' },
        }),
        transformResponse: userUpdateResponseHandling,
      },
    ),
    deleteUser: build.mutation<{ success: boolean }, void>({
      query: () => ({
        url: `/user/delete`,
        method: 'DELETE',
        headers: { authorization: 'true' },
      }),
    }),
  }),
});

export const {
  useGetUserAddressesQuery,
  useGetUserOrdersQuery,
  useUpdateUserMutation,
  useDeleteUserMutation,
} = userApi;
