const banknotes = [100, 500, 1000, 2000, 5000];
const roundingValues = [100, 500, 1000] as const;

const rounding = (number: number, param: (typeof roundingValues)[number]) =>
  Math.ceil(number / param) * param;

export const getBanknotes = (number: number) => {
  let result = [0];
  if (number === 0) {
    return result;
  }

  roundingValues.forEach((value) => {
    const rounded = rounding(number, value);
    if (!result.includes(rounded) && rounded !== number) {
      result.push(rounded);
    }
  });

  result = result.concat(
    banknotes.filter(
      (banknote) => banknote > Math.max(...result) && banknote > number,
    ),
  );

  return result;
};
