import { useModal } from '@ebay/nice-modal-react';
import { modalIds } from '@shared/config/modal-ids';

export const useVideoModal = () => {
  const modal = useModal(modalIds.VideoModal);

  return {
    ...modal,
    show: (args: { videoLink: string; title: string }) => modal.show(args),
  };
};
