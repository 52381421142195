import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

function getWorkerPath(language: string) {
  switch (language) {
    case 'ua':
      return '/public/ss/service-worker-ua.js';
    case 'pl':
      return '/public/ss/service-worker-pl.js';
    default:
      return '/public/service-worker.js';
  }
}

const register = (language: string) => {
  const workerPath = getWorkerPath(language);

  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register(workerPath);
    //     .then((registration) => {
    //       console.log('Service worker registration succeeded:', registration);
    //     })
    //     .catch((error) => {
    //       console.error(`Service worker registration failed: ${error}`);
    //     });
    // } else {
    //   console.error('Service workers are not supported.');
  }
};

export function SwRegister() {
  const { i18n } = useTranslation();

  useEffect(() => {
    register(i18n.language);
  }, [i18n.language]);

  return null;
}
