import { ReactNode } from 'react';

export function CatalogGrid({
  maxInRow,
  children,
}: {
  maxInRow: '3' | '4';
  children: ReactNode;
}) {
  const gridStyle =
    maxInRow === '4'
      ? 'grid-cols-2 md:grid-cols-3 2xl:grid-cols-4'
      : 'grid-cols-1 sm:grid-cols-2 lg:grid-cols-3';

  return (
    <ul className={`grid ${gridStyle} gap-3 sm:gap-4 lg:gap-6`}>{children}</ul>
  );
}
