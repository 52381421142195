import parse, {
  attributesToProps,
  // DOMNode,
  domToReact,
} from 'html-react-parser';

export const htmlParse = (
  data: string,
  replaceData?: {
    from: string;
    to: (props: Record<string, unknown>, children: any) => JSX.Element;
  }[],
) => {
  const replace = (domNode: any) => {
    if (domNode.type === 'tag') {
      const { attribs, name, children } = domNode;

      const replacement = replaceData?.find((item) => item.from === name);

      if (replacement) {
        const props = attributesToProps(attribs);
        return replacement.to(
          props,
          domToReact(children as any, {
            replace,
          }),
        );
      }
    }
    return domNode;
  };

  return parse(data, replaceData ? { replace } : undefined);
};
