import productHelper from 'modules/helpers/product-helper';
import axios from 'axios';
import * as session from 'modules/session';
import type {
  IAction,
  IActionGiftSelect,
  IActionSettings,
  ICart,
  ICartItem,
} from 'types/cartTypes';
import { IProduct } from 'types/product';
import Emitter from 'frontend/Components/NewCartOrder/Emitters';
import { TFunction } from 'i18next';
import globalStore from '../global-store';
import api from './api';

function uniq<T>(array: T[]): T[] {
  const seen = new Set<T>();
  return array.filter((item) => {
    if (!seen.has(item)) {
      seen.add(item);
      return true;
    }
    return false;
  });
}
// @todo to refactoring
function sendOrderField(fieldName: any, value: any) {
  return new Promise((resolve, reject) => {
    axios
      .post('/cart/order', { [fieldName]: value })
      .then(() => {
        const cart = session.get('cart') || {};
        if (!cart.order) cart.order = {};
        cart.order[fieldName] = value;
        session.set('cart', cart);
        resolve(true);
      })
      .catch(reject);
  });
}

function getCartItems(cart: ICart) {
  const { items } = cart || {};
  return Array.isArray(items) ? items : [];
}

/**
 * Получить массив продуктов входящих в выбранные подарки
 * Массив не уникальный, продукты могут повторяться
 * @param cart
 * @returns {Array}
 */
function getSelectedGiftsProducts(cart: ICart) {
  if (!cart) return [];
  const { actions = [], giftSettings = {} } = cart;
  const products: IActionGiftSelect[] = [];
  Object.keys(giftSettings).forEach((key) => {
    const setting = giftSettings[+key];
    if (setting.disable) return;
    const selectedIndex = setting.select;
    const action = actions.find(({ id }) => id === +key);
    if (!action) return;
    const { action: { gift_select } = {} as IActionSettings } = action;
    const productGroup = gift_select[selectedIndex];
    if (!productGroup) return;
    products.push(...productGroup);
  });
  return products;
}

function getCategoryMaxCutlery(id: number): number {
  const product: IProduct = productHelper.getCartProduct(id);
  if (!product) {
    return 1; // if products not find => return 1, fix bug [SW-2449]
  }
  const menu = globalStore.get('menu');
  const category = menu.find(
    ({ id: catId }: { id: number }) => catId === product.menu_id,
  );

  if (!category) return 0;
  return category.max_cutlery || 0;
}

/**
 * Получить максимально доступное количество приборов.
 * Завсит от количества продуктов в корзине и выбранных подарков
 * @param cart
 * @returns {number}
 */
function getCutleryMaxQty(cart: ICart) {
  if (!cart) return 0;
  const items = getCartItems(cart);
  if (items.length === 0) return 0;
  let maxCutlery = 0;
  const gifts = getSelectedGiftsProducts(cart);
  [...items, ...gifts].forEach(({ qty = 1, product_id }) => {
    maxCutlery += qty * getCategoryMaxCutlery(product_id);
  });

  return maxCutlery;
}

function getActionProducts(action: IActionSettings) {
  if (!action) {
    return [];
  }
  const { gift_select = [] } = action;
  if (!Array.isArray(gift_select)) {
    return [] as IActionGiftSelect[];
  }

  const products = [] as IActionGiftSelect[];
  gift_select.forEach((items) => {
    items.forEach((item) => {
      products.push(item);
    });
  });
  return products;
}

function getGiftsProducts(cart: ICart) {
  if (!cart) return [];
  const { actions = [] as IAction[] } = cart;
  let products = [] as IActionGiftSelect[];
  actions.forEach(({ action = {} as IActionSettings }) => {
    products = products.concat(getActionProducts(action));
  });

  return products;
}

function getRecommendationProducts(cart: ICart) {
  if (!cart) return [];
  const { recommendations = [] } = cart;
  let products: any = [];
  recommendations.forEach(({ action: stock = {} }) => {
    const { action } = stock;
    products = products.concat(getActionProducts(action));
  });
  return products;
}

function getProductAndChildIds(products: IActionGiftSelect[] | ICartItem[]) {
  if (!Array.isArray(products)) return [];
  const ids: number[] = [];
  products.forEach((product) => {
    if (!ids.find((i) => i === product.product_id)) {
      ids.push(product.product_id);
    }
    if (product.child && product.child.length) {
      product.child.forEach((child) => {
        if (!ids.find((i) => i === child.product_id)) {
          ids.push(child.product_id);
        }
      });
    }
  });
  return ids;
}

function getUserGiftProducts(): number[] {
  const userGifts = session.get('userGifts') || [];
  return userGifts.map(({ product_id }: { product_id: number }) => product_id);
}

// Получить массив ВЫБРАННЫХ подарков юзера (userGifts)
function getSelectedUserGiftProducts() {
  const userGifts = session.get('userGifts') || [];
  if (!userGifts || !Array.isArray(userGifts) || !userGifts.length) {
    return [];
  }
  return userGifts.filter((item) => item.qty > 0);
}

/**
 * Получить id продуктов из корзины, id ВЫБРАННЫХ продуктов из акции
 * и id ВЫБРАННЫХ подарков
 * @param cart
 * @returns { Array } - массив ids продуктов
 */
function getActiveProductIds(cart: ICart) {
  if (typeof cart !== 'object') return [];
  const items = getCartItems(cart);
  const productIds = getProductAndChildIds(items);
  const giftsProducts = getSelectedGiftsProducts(cart);
  const giftsProductIds = getProductAndChildIds(giftsProducts);
  const userGiftProducts = getSelectedUserGiftProducts();
  const userGiftProductsIds = getProductAndChildIds(userGiftProducts);
  return uniq([...productIds, ...giftsProductIds, ...userGiftProductsIds]);
}

/**
 * Получить id всех продуктов корзины
 * @param cart
 * @returns { Array } - массив ids продуктов
 */
function getProductIds(cart: ICart) {
  if (typeof cart !== 'object') return [];
  const productIds = getProductAndChildIds(getCartItems(cart));
  const giftsProductIds = getProductAndChildIds(getGiftsProducts(cart));
  const recommendationProductIds = getProductAndChildIds(
    getRecommendationProducts(cart),
  );
  const userGiftProducts = getUserGiftProducts();

  return uniq([
    ...productIds,
    ...giftsProductIds,
    ...recommendationProductIds,
    ...userGiftProducts,
  ]);
}

function removeProduct(productIds: number | number[], cart: ICart) {
  const copyCard = JSON.parse(JSON.stringify(cart));

  const prodIds = Array.isArray(productIds) ? productIds : [productIds];

  copyCard.items = getCartItems(copyCard).filter((item) => {
    if (item.child && Array.isArray(item.child)) {
      // remove if some childs is removed
      if (item.child.some((child) => prodIds.includes(+child.product_id))) {
        return false;
      }
    }
    return !prodIds.includes(+item.product_id);
  });

  return copyCard;
}

const afterCartItem = async ({
  newCart,
  t,
  item,
  onError,
}: {
  newCart: ICart;
  t: TFunction;
  onError: (text: string) => void;
  item?: { id: number; qty: number; child?: any };
}) => {
  try {
    session.set('cart', newCart);
    const ids: number[] = [];
    const productsId = getProductIds(newCart);
    productsId.forEach((id) => {
      if (!productHelper.getCartProduct(id)) {
        ids.push(id);
      }
    });
    if (ids.length) {
      const products = await api('product.get', { id: ids });
      products.forEach((product: IProduct) => {
        productHelper.storeCartProduct(product);
      });
    }
    Emitter.emit('CART_CHANGE');
  } catch (error: any) {
    console.log('error: ', error);
    const { message } = error.data || {};
    if (message === 'bonus_phone_code_invalid') {
      try {
        const cart = (await api('cart.bonus_phone')) || {};

        session.set('cart', cart);
        Emitter.emit('CART_CHANGE');
        onError(t(`localization.bonus_phone_code_invalid`));
      } catch (error) {
        console.error(error);
      }
    } else if (message === 'not_found_product') {
      const { errors: ids } = error.data.data || {};
      if (Array.isArray(ids)) {
        let cart = session.get('cart');
        cart = removeProduct(ids, cart);
        session.set('cart', cart);
        Emitter.emit('CART_CHANGE');
        onError(t(`localization.bonus_phone_code_invalid`));
      }
    } else if (message === 'need_product_promocode' && item) {
      const cart = (await api('cart.get')) || {};
      session.set('cart', cart);
      Emitter.emit('CART_CHANGE');

      Emitter.emit('MODAL_CALL', {
        modal: 'NeedPromocodeModal',
        value: { item },
      });
    } else {
      Emitter.emit('CART_CHANGE');
    }
  }
};

export {
  sendOrderField,
  getCutleryMaxQty,
  getActiveProductIds,
  getCartItems,
  getSelectedGiftsProducts,
  getProductAndChildIds,
  getGiftsProducts,
  getProductIds,
  afterCartItem,
};
