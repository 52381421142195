import { useEffect, useState } from 'react';
import productHelper from 'modules/helpers/product-helper';
import api from 'modules/helpers/api';
import * as session from 'modules/session';
import { IUserGift } from 'types/userTypes';
import { IProduct } from 'types/product';
import Card from './Card/Card';
import Emitter from '../NewCartOrder/Emitters';

interface IUserGifts {
  isExact: boolean;
  setIsNotEmptyUserGifts: (isNotEmptyUserGifts: boolean) => void;
}

function UserGifts({ isExact, setIsNotEmptyUserGifts }: IUserGifts) {
  const [userGifts, setUserGifts] = useState(
    (session.get('userGifts') || []) as IUserGift[],
  );

  const onUserGiftsChange = () => {
    setUserGifts((session.get('userGifts') || []) as IUserGift[]);
  };

  const userGiftChange = (userGift: IUserGift) => {
    api('cart.user_gift', userGift).then((products: IUserGift[] = []) => {
      const filtredProducts = userGifts.map((item) => {
        const { product_id, maxQty } = item;
        const { qty = 0 } =
          products.find((gift) => gift.product_id === product_id) || {};
        return { product_id, maxQty, qty } as IUserGift;
      });
      session.set('userGifts', filtredProducts);
      Emitter.emit('USER_GIFTS_CHANGE');
      setUserGifts(filtredProducts);
    });
  };

  // Очень странный юзэффект, может какой то костыль?...
  // useEffect(() => {
  //   setUserGifts(userGifts);
  // }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        const { products: userProducts = [] }: { products: IUserGift[] } =
          await api('user.get_bonuses');
        const filtredProducts = userProducts.map((item) => {
          const { product_id, qty: maxQty } = item;
          const { qty = 0 } =
            userGifts.find((gift) => gift.product_id === product_id) || {};
          return { product_id, maxQty, qty } as IUserGift;
        });
        session.set('userGifts', filtredProducts);
        setUserGifts(filtredProducts);
        setIsNotEmptyUserGifts(filtredProducts.length !== 0);
      } catch {
        setIsNotEmptyUserGifts(false);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    Emitter.addListener('USER_GIFTS_CHANGE', onUserGiftsChange);
    return () => {
      Emitter.removeListener('USER_GIFTS_CHANGE', onUserGiftsChange);
    };
  }, []);

  if (!userGifts.length) return null;

  return (
    <>
      {userGifts.map(({ product_id, qty, maxQty }) => {
        const product: IProduct = productHelper.get(product_id);
        if (!product) {
          return;
        }

        return (
          <Card
            product={product}
            key={product_id}
            maxQty={maxQty}
            qty={qty}
            isExact={isExact}
            changeQty={userGiftChange}
          />
        );
      })}
    </>
  );
}

export default UserGifts;
