import { Element } from 'react-scroll';
import { ElementProps } from 'react-scroll/modules/components/Element';

export function ScrollAnchor({
  ref: _,
  name,
  children,
  ...rest
}: ElementProps) {
  return (
    <Element name={name} {...rest}>
      {children}
    </Element>
  );
}
