import { useEffect, useState } from 'react';

export const useIntervalIndex = (maxIndex: number, interval: number) => {
  const getRandomInt = (max: number) => Math.floor(Math.random() * max);
  const [index, setIndex] = useState<number>(0);

  useEffect(() => {
    if (maxIndex <= 1) {
      return;
    }
    const animateInterval = setInterval(() => {
      let newIndex;
      // цикл do while здесь для поиска не повторяющегося значения нового индекса с предыдущим
      do {
        newIndex = getRandomInt(maxIndex);
      } while (newIndex === index);

      setIndex(newIndex);
    }, interval);

    return () => {
      clearInterval(animateInterval);
    };
  }, [index, interval, maxIndex]);

  return index;
};
