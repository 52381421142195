import { FetchLoaderType } from '@shared/lib/routing';
import type { INews, IArticle } from '@entities/publication';
import { ITrioItem } from '@pages/main-page/trio-on-main/model/types';
import { ISeoPage } from '@widgets/seoPage';
import { BlockNames, IMainPageItem } from './model/types';
import { IPromoLinks } from '../main-promo/model/types';
import { IMainSlider } from '../main-slider/model/types';

type BlockData<M> = {
  [K in keyof M]: {
    name: K;
    data: Awaited<M[K]>;
  };
}[keyof M];

export const mainPageLoader = async (
  fetch: FetchLoaderType,
  { params, lang }: { params: { city: string }; lang: string },
) => {
  const fetchParams = { city: params.city, lang };
  const mainPageResponse = await fetch<{ mainpage: IMainPageItem[] }>({
    url: '/setting/mainpage',
    params: fetchParams,
  });
  const seoPageResponse = await fetch<ISeoPage>({
    url: '/seo/page',
    params: {
      url: `/`,
      ...fetchParams,
    },
  });
  const methods = {
    [BlockNames.promos]: fetch<{ promo_links: IPromoLinks }>({
      url: '/setting/promo_links',
      params: fetchParams,
    }).then((res) => res.data.promo_links),
    [BlockNames.stocks]: fetch<{ stock: ITrioItem[] }>({
      url: '/stock/main',
      params: fetchParams,
    }).then((res) => res.data.stock),
    [BlockNames.news]: fetch<{ news: INews[] }>({
      url: '/news/main',
      params: fetchParams,
    }).then((res) => res.data.news),
    [BlockNames.articles]: fetch<{ news: IArticle[] }>({
      url: '/articles/main',
      params: fetchParams,
    }).then((res) => res.data.news),
    [BlockNames.slider]: fetch<{ slider: IMainSlider[] }>({
      url: '/banner/slider',
      params: fetchParams,
    }).then((res) => res.data.slider),
  };

  const sortedActiveBlocks = mainPageResponse.data.mainpage
    .filter((block) => block.active)
    .sort((a, b) => a.order - b.order);
  const promises = sortedActiveBlocks.map((block) => {
    const method = methods[block.name];
    return method;
  });

  const response = await Promise.all(promises);

  const blocksData = response.map((item, index) => {
    const { name } = sortedActiveBlocks[index];
    return {
      name,
      data: item,
    } as BlockData<typeof methods>;
  });

  return {
    seoPageData: seoPageResponse.data,
    blocksData,
  };
};
