import { useSSR, initReactI18next } from 'react-i18next';
import { options, i18nextInstance } from '@shared/i18n';
import { ResourceLanguage } from 'i18next';

i18nextInstance.use(initReactI18next).init(options);

interface IWindowWithlI18n extends Window {
  initialI18nStore: Record<string, ResourceLanguage>;
  initialLanguage: string;
}
const windowWithlI18n = window as unknown as IWindowWithlI18n;

export function I18nProvider({ children }: { children: JSX.Element }) {
  useSSR(windowWithlI18n.initialI18nStore, windowWithlI18n.initialLanguage);
  return children;
}
